import { get, post } from './http-calls';

export const getAllvehiclecheckchecks = async () => {
    let res = await get(`vehiclecheckchecks`, { useAuthToken: true });
    return res;
};

export const getvehiclecheckchecksById = async (id) => {
    let res = await get(`vehiclecheckchecks/${id}`, { useAuthToken: true });
    return res;
};
export const getInspectionSheetTypebyId = async (id) => {
    let res = await get(`InspectionSheetType/GetDetails/${id}`, { useAuthToken: true });
    return res;
};

export const postvehiclecheckchecksData = async (data) => {
    let res = await post(`vehiclecheckchecks`, data, { useAuthToken: true });
    return res;
};
export const getVehicleCategory = async (data) => {
    let res = await get(`VehicleCategories`, { useAuthToken: true });
    return res;
};
export const getVehicleDocsCategory = async (data) => {
    let res = await get(`VehicleCheckChecks/InspectionSheetTypes_List`, { useAuthToken: true });
    return res;
};

export const getVehicleCheckSection = async (data) => {
    let res = await get(`vehiclechecksection`, { useAuthToken: true });
    return res;
};

export const postVehicleInspectionSheetType = async (data) => {
    let res = await post(`InspectionSheetType`, data, { useAuthToken: true });
    return res;
};

export const getAllVehicleCheckSection = async () => {
    let res = await get(`VehicleCheckSection`, { useAuthToken: true });
    return res;
};

export const getVehicleCheckSectionById = async (id) => {
    let res = await get(`VehicleCheckSection/${id}`, { useAuthToken: true });
    return res;
};

export const postVehicleCheckSectionData = async (data) => {
    let res = await post(`VehicleCheckSection`, data, { useAuthToken: true });
    return res;
};

export const getAllVehicleCheckSectionDropDown = async () => {
    let res = await get(`VehicleCategories`, { useAuthToken: true });
    return res;
};
