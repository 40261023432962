import { FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core';
import './loanStyle.scss';

import { Alert } from '@material-ui/lab';
import { SignPad } from '../../../Core/SignaturePad/SignPad';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { useContext } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';
import ImageUploadModal from './ImageUpload/ImageUploadModal';
import BackupIcon from '@material-ui/icons/Backup';

export function HandoverOUtSignature() {
    const { fieldChange, clearSig, state, handleOutImageUpload, handleOutRemoveImage, loanVehicleOutImages, errors } = useContext(LoanVehicleContext);
    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <div color="secondary" className="Loan_Veh_heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Vehicle Handover - OUT</span>
                    <ImageUploadModal
                        title="Vehicle Handover - OUT"
                        handleImageUpload={handleOutImageUpload}
                        handleRemoveImage={handleOutRemoveImage}
                        images={loanVehicleOutImages}
                        disabled={state.isVehicleReceived}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info" icon={false}>
                    Please upload images of any external vehicle damage before the vehicle leaves the premises.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                {state.handoverCustomerSignatureOut ? (
                    <>
                        <FormLabel component="legend" required error>
                            Customer signature
                        </FormLabel>
                        <br />
                        <div style={{ width: '100%', height: '177px', border: '1px solid #f5f5f5' }}>
                            <img src={state.handoverCustomerSignatureOut} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                    </>
                ) : (
                    <CustomeSignPad
                        content="Customer Signature"
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.handoverCustomerSignatureOut}
                        name="handoverCustomerSignatureOut"
                        isRequired
                        disabled={state.isVehicleReceived}
                    />
                )}
                {errors.handoverCustomerSignatureOut && <FormHelperText error>{errors.handoverCustomerSignatureOut}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {state.handoverStaffSignatureOut ? (
                    <>
                        <FormLabel component="legend" required error>
                            Staff signature
                        </FormLabel>
                        <br />
                        <div style={{ width: '100%', height: '177px', border: '1px solid #f5f5f5' }}>
                            <img src={state.handoverStaffSignatureOut} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                    </>
                ) : (
                    <CustomeSignPad
                        content="Staff Signature"
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.handoverStaffSignatureOut}
                        name="handoverStaffSignatureOut"
                        isRequired
                        disabled={state.isVehicleReceived}
                    />
                )}
                {errors.handoverStaffSignatureOut && <FormHelperText error>{errors.handoverStaffSignatureOut}</FormHelperText>}
            </Grid>
        </Grid>
    );
}
export function HandoverReturnSignature() {
    const { fieldChange, clearSig, state, handleInImageUpload, handleInRemoveImage, loanVehicleInImages, errors } = useContext(LoanVehicleContext);
    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <div color="secondary" className="Loan_Veh_heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Vehicle Handover - RETURN</span>
                    <ImageUploadModal
                        title="Vehicle Handover - RETURN"
                        handleImageUpload={handleInImageUpload}
                        handleRemoveImage={handleInRemoveImage}
                        images={loanVehicleInImages}
                        disabled={state.isVehicleReceived}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info" icon={false} style={{ fontSize: '13px' }}>
                    Please upload images of any external vehicle damage before the vehicle leaves the premises.
                </Alert>
            </Grid>

            <Grid item xs={12}>
                {state.handoverCustomerSignatureIn ? (
                    <>
                        <FormLabel component="legend" required error>
                            Customer signature
                        </FormLabel>
                        <br />
                        <div style={{ width: '100%', height: '177px', border: '1px solid #f5f5f5' }}>
                            <img src={state.handoverCustomerSignatureIn} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                    </>
                ) : (
                    <CustomeSignPad
                        content="Customer Signature"
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.handoverCustomerSignatureIn}
                        name="handoverCustomerSignatureIn"
                        isRequired
                        disabled={state.isVehicleReceived}
                    />
                )}
                {errors.handoverCustomerSignatureIn && <FormHelperText error>{errors.handoverCustomerSignatureIn}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {state.handoverStaffSignatureIn ? (
                    <>
                        <FormLabel component="legend" required error>
                            Staff signature
                        </FormLabel>
                        <br />
                        <div style={{ width: '100%', height: '177px', border: '1px solid #f5f5f5' }}>
                            <img src={state.handoverStaffSignatureIn} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                    </>
                ) : (
                    <CustomeSignPad
                        content="Staff Signature"
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.handoverStaffSignatureIn}
                        name="handoverStaffSignatureIn"
                        isRequired
                        disabled={state.isVehicleReceived}
                    />
                )}
                {errors.handoverStaffSignatureIn && <FormHelperText error>{errors.handoverStaffSignatureIn}</FormHelperText>}
            </Grid>
        </Grid>
    );
}
