import React, { useState, useMemo } from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ManageAccountGrid from '../../../../managementAccount/manageAccountGrid/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Nominal Account Divisions', active: true }
];

const basecolumns = [
    { field: 'divisionCode', title: 'Division Code', width: 70 },
    { field: 'costCentre', title: 'Cost Centre', width: 150 },
    { field: 'currentPeriod', title: 'Current Period', width: 70 },
    { field: 'currentYear', title: 'Current Year', width: 55 },
    { field: 'startOfCurrentPrd', title: 'Start of Current Period', renderer: formatters.DateFormatter, width: 90 },
    { field: 'endOfCurrentPrd', title: 'End of Current Period', renderer: formatters.DateFormatter, width: 90 },
    { field: 'lastUpdated', title: 'Last Updated', renderer: formatters.DateTimeFormatter, width: 80 }
    // { field: 'total', title: 'Total' }
];

const AccountDivisionListScreen = () => {
    const [state, setState] = useState({
        showLoader: true,
        selectedRowData: {}
    });
    const history = useHistory();

    const redirectDescription = (divisionCode) => {
        history.push({
            pathname: `/mapping/nominalDepartment`,
            search: `divisionCode=${divisionCode}`
        });
    };
    const renderDescription = (field, row) => {
        console.log(field, 'field');
        // console.log(grid, 'grid');
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => redirectDescription(row.divisionCode)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {field}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        tempCols.splice(1, 0, { field: 'divisionDescription', title: 'Description', width: 120, renderer: renderDescription });
        return tempCols;
    }, []);
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <ManageAccountGrid
                columns={columns}
                getUrl={`NominalAccountingDivisions/AccountingDivisions_List`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
            />
        </div>
    );
};

export default AccountDivisionListScreen;
