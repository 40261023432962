import { get, post } from './http-calls';

export const getCabDeclarations = async (HeaderRecordsID) => {
    let res = await get(`CabDeclarations/${HeaderRecordsID}`, { useAuthToken: true });
    return res;
};

export const saveCabDeclarations = async (data) => {
    let res = await post(`CabDeclarations`, data, { useAuthToken: true });
    return res;
};
