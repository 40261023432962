import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { TechnicianWriteUpGetHistory } from "../../../Core/Service/techWriteUp-service";

function TechWriteUpHistory(props) {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  const GetHistory = async () => {
    let res = await TechnicianWriteUpGetHistory(props.HeaderRecordID);
    setLoading(false);
    if (res.success) {
      setHistoryData(res.data.list);
    }
  };
  useEffect(() => {
    GetHistory();
  }, []);

  return (
    <>
  {loading ? (
    <div style={{ textAlign: "center" }}>
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        maxHeight: "500px", 
        minHeight: "400px", 
        overflowY: "auto", 
        padding: "10px",
        marginBottom:"30px"
      }}
    >
      {historyData.length === 0 ? (
        <Typography>No history data available.</Typography>
      ) : (
        <Grid container spacing={2} style={{marginBottom:"10px"}}>
        {historyData.map((entry, index) => (
            <Grid
              item
              xs={12}
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#F6EEEC" : "#ECF4F6",
                marginBottom: "2px",
              }}
            >
              <Typography variant="body1">{entry.note}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  )}
</>
  );
}
export default TechWriteUpHistory;