import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import ViewPdfModal from '../../../../Core/Modal/PdfViweModal';
import { getInvoicePdf } from '../../../../Core/Service/internal_Invoice-service';
import AppContext from '../../../../App/AppContext';

const NominalTransInvoice = ({ invoiceFileName }) => {
    const { showToast } = useContext(AppContext);
    const [pdfFileData, setPdfFileData] = useState(null);

    useEffect(() => {
        const fetchPdf = async () => {
            if (invoiceFileName) {
                let res = await getInvoicePdf(invoiceFileName);
                if (res) {
                    setPdfFileData(res);
                } else {
                    console.error("Failed to fetch PDF");
                }
            }
        };

        if (invoiceFileName) {
            fetchPdf();
        }
    }, [invoiceFileName]); 

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ViewPdfModal pdfFileData={pdfFileData} height={900} width={900}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default NominalTransInvoice;
