import { Grid, FormLabel, FormControlLabel, InputLabel, RadioGroup, Radio, FormControl } from '@material-ui/core';
import React from 'react';
import TabView from '../../../Core/Controls/Tabs';
import { SecondaryCheckbox, Multiselect, SelectBox, YesNoButton } from '../../../Core/FormInput';
import EwipModules from './ewipModule';

export default function UserModules(props) {
    const {
        state,
        handleCheckbox,
        selectedData,
        portalSettings,
        process,
        processData,
        handleClick,
        fieldChange,
        appraisalVehicle,
        AutoAppraisalVehiclechange,
        appraisalVehicleOption
    } = props;
    const tabs = [
        ...(portalSettings.portalSettingeWIPEnabled
            ? [
                  {
                      label: 'eWIP',
                      body: (
                          <Grid container spacing={1} style={{ padding: 4 }}>
                              <EwipModules
                                  handleClick={handleClick}
                                  brancheData={props.brancheData}
                                  AutoBrChange={props.AutoBrChange}
                                  masterData={props.masterData}
                                  AutoWorkChange={props.AutoWorkChange}
                                  AutoPrChange={props.AutoPrChange}
                                  state={state}
                                  handleCheckbox={handleCheckbox}
                                  selectedData={selectedData}
                                  process={process}
                                  processData={processData}
                              />
                          </Grid>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingReportingEnabled && (state.userType == 'internal' || state.userType == 'technician')
            ? [
                  {
                      label: 'Reporting',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormLabel component="legend" className="btn_add">
                                      Reporting Access
                                  </FormLabel>
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eReportingAllowed} onChange={handleCheckbox} name="eReportingAllowed" />}
                                      label="Reporting Allowed?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.canAccessBudget} onChange={handleCheckbox} name="canAccessBudget" />}
                                      label="Budget Allowed?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={
                                          <SecondaryCheckbox
                                              checked={state.canAccessSalesPerformance}
                                              onChange={handleCheckbox}
                                              name="canAccessSalesPerformance"
                                          />
                                      }
                                      label="Access Sales Performance ?"
                                  />
                              </Grid>

                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isAgedMeasure} onChange={handleCheckbox} name="isAgedMeasure" />}
                                      label="Aged Measure?"
                                  />
                                  <FormControlLabel control={<SecondaryCheckbox checked={state.isDOC} onChange={handleCheckbox} name="isDOC" />} label="DOC" />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isKPIs} onChange={handleCheckbox} name="isKPIs" />}
                                      label="KPIs"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isParts} onChange={handleCheckbox} name="isParts" />}
                                      label="Parts"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isSales} onChange={handleCheckbox} name="isSales" />}
                                      label="Sales"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isService} onChange={handleCheckbox} name="isService" />}
                                      label="Service"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isWorkShop} onChange={handleCheckbox} name="isWorkShop" />}
                                      label="Workshop"
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                  <FormLabel component="legend" className="btn_add">
                                      Manage Account
                                  </FormLabel>
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={
                                          <SecondaryCheckbox checked={state.canAccessNominalMapping} onChange={handleCheckbox} name="canAccessNominalMapping" />
                                      }
                                      label="Nominal Mapping?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={
                                          <SecondaryCheckbox
                                              checked={state.canAccessNominalAccounts}
                                              onChange={handleCheckbox}
                                              name="canAccessNominalAccounts"
                                          />
                                      }
                                      label="Nominal Accounts?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={
                                          <SecondaryCheckbox
                                              checked={state.canAccessNominalTransactions}
                                              onChange={handleCheckbox}
                                              name="canAccessNominalTransactions"
                                          />
                                      }
                                      label="Nominal Transactions?"
                                  />
                              </Grid>
                          </Grid>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingCommunicatorEnabled && state.userType != 'auditor'
            ? [
                  {
                      label: 'Communicator ',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eCommunicatorAllowed} onChange={handleCheckbox} name="eCommunicatorAllowed" />}
                                      label="Communicator Allowed?"
                                  />
                              </Grid>
                              {state.userType === 'customer' && (
                                  <Grid item xs={12}>
                                      <InputLabel style={{ marginBottom: 10 }} shrink>
                                          Customer Groups
                                      </InputLabel>
                                      <SelectBox
                                          List={props.custmerGroup}
                                          name="userCustomerGroupID"
                                          value={state.userCustomerGroupID}
                                          onChange={props.fieldChange}
                                      />
                                  </Grid>
                              )}
                              {state.userType == 'technician' && (
                                  <Grid container spacing={1} item xs={12}>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Technician?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canAssignTechnician}
                                                  onNoClick={() => handleClick('canAssignTechnician', false)}
                                                  onYesClick={() => handleClick('canAssignTechnician', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Customer Rating?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canSetCustomerRating}
                                                  onNoClick={() => handleClick('canSetCustomerRating', false)}
                                                  onYesClick={() => handleClick('canSetCustomerRating', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Job Priority?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canAssignJobPriority}
                                                  onNoClick={() => handleClick('canAssignJobPriority', false)}
                                                  onYesClick={() => handleClick('canAssignJobPriority', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              )}
                          </Grid>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingInvoiceEnabled && state.userType != 'auditor'
            ? [
                  {
                      label: 'Invoice ',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eInvoiceAllowed} onChange={handleCheckbox} name="eInvoiceAllowed" />}
                                      label="Invoice Allowed?"
                                  />
                              </Grid>
                              {state.userType == 'technician' && (
                                  <Grid container spacing={1} item xs={12}>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Technician?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canAssignTechnician}
                                                  onNoClick={() => handleClick('canAssignTechnician', false)}
                                                  onYesClick={() => handleClick('canAssignTechnician', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Customer Rating?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canSetCustomerRating}
                                                  onNoClick={() => handleClick('canSetCustomerRating', false)}
                                                  onYesClick={() => handleClick('canSetCustomerRating', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                          <Grid item xs={12}>
                                              Can Assign Job Priority?
                                          </Grid>
                                          <Grid item xs={10}>
                                              <YesNoButton
                                                  state={state.canAssignJobPriority}
                                                  onNoClick={() => handleClick('canAssignJobPriority', false)}
                                                  onYesClick={() => handleClick('canAssignJobPriority', true)}
                                              />
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              )}
                          </Grid>
                      )
                  }
              ]
            : []),
        //PortalSettingsAppraisalEnabled
        ...(portalSettings.portalSettingsAppraisalEnabled && (state.userType == 'internal' || state.userType == 'customer')
            ? [
                  {
                      label: 'Appraisal ',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormControlLabel
                                      control={
                                          <SecondaryCheckbox
                                              checked={state.canAccessAppraisalPortal}
                                              onChange={handleCheckbox}
                                              name="canAccessAppraisalPortal"
                                          />
                                      }
                                      label="Appraisal Allowed?"
                                  />
                              </Grid>
                              {state.userType != 'customer' && (
                                  //for customer pass default appraiserid as customer
                                  <Grid item row xs={12}>
                                      <FormControl component="fieldset">
                                          <FormLabel>Select Appraiser Role</FormLabel>
                                          <Grid item xs={12}>
                                              <RadioGroup
                                                  row
                                                  aria-label="appraisalRoleID"
                                                  name="appraisalRoleID"
                                                  value={state.appraisalRoleID}
                                                  onChange={fieldChange}
                                              >
                                                  {state.appraiserRoleList.map((m) => {
                                                      return <FormControlLabel value={m.id} control={<Radio />} label={m.name} />;
                                                  })}
                                              </RadioGroup>
                                          </Grid>
                                      </FormControl>
                                  </Grid>
                              )}
                              <Grid item xs={12}>
                                  <Multiselect
                                      label="Vehicle Types"
                                      options={appraisalVehicleOption}
                                      value={selectedData.appraisalVehicles}
                                      onChange={AutoAppraisalVehiclechange}
                                  />
                              </Grid>
                          </Grid>
                      )
                  }
              ]
            : [])
    ];

    return (
        <div className="screen">
            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />
        </div>
    );
}
