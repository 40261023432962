import { useContext } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';
import { InputLabel, Grid } from '@material-ui/core';
import { TextBox, DatePicker } from '../../../Core/FormInput';
import BookingDetails from './bookingDetails';

const InsuranceDetails = () => {
    const { insuranceDetails, handleInsuranceDetails, state } = useContext(LoanVehicleContext);

    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <div className="Loan_Veh_heading">Insurance Details</div>
            </Grid>
            {/* <Grid item xs={4}>
                <InputLabel shrink component="legend">
                    Company Name
                </InputLabel>
                <TextBox
                    name="insuranceCompanyName"
                    value={insuranceDetails.insuranceCompanyName}
                    onChange={handleInsuranceDetails}
                    required
                    type="text"
                    maxLength={100}
                />
            </Grid>

            <Grid item xs={8}>
                <InputLabel shrink component="legend">
                    Company Address
                </InputLabel>
                <TextBox
                    name="insuranceCompanyAddress"
                    value={insuranceDetails.insuranceCompanyAddress}
                    onChange={handleInsuranceDetails}
                    required
                    type="text"
                    maxLength={200}
                    multiline
                />
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel shrink component="legend">
                            Company Name
                        </InputLabel>
                        <TextBox
                            name="insuranceCompanyName"
                            value={insuranceDetails.insuranceCompanyName}
                            onChange={handleInsuranceDetails}
                            // required
                            // label="Company Name"
                            type="text"
                            maxLength={100}
                            disabled={state.isVehicleReceived}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <InputLabel shrink component="legend">
                            Company Address
                        </InputLabel> */}
                        <TextBox
                            name="insuranceCompanyAddress"
                            value={insuranceDetails.insuranceCompanyAddress}
                            onChange={handleInsuranceDetails}
                            // required
                            type="text"
                            maxLength={200}
                            multiline
                            rows={2}
                            label="Company Address"
                            disabled={state.isVehicleReceived}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel shrink component="legend">
                            Policy Number
                        </InputLabel>
                        <TextBox
                            name="insuranceCompanyPolicyNumber"
                            value={insuranceDetails.insuranceCompanyPolicyNumber}
                            onChange={handleInsuranceDetails}
                            // required
                            // label="Policy Number"
                            type="text"
                            maxLength={50}
                            disabled={state.isVehicleReceived}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel shrink component="legend" style={{ marginBottom: 10 }}>
                            Policy Start Date
                        </InputLabel>
                        <DatePicker
                            name="insurancePolicyStartDate"
                            value={insuranceDetails.insurancePolicyStartDate}
                            onChange={handleInsuranceDetails}
                            // required
                            // label="Policy Start Date"
                            disabled={state.isVehicleReceived}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel shrink component="legend" style={{ marginBottom: 10 }}>
                            Policy Renewal Date
                        </InputLabel>
                        <DatePicker
                            name="insurancePolicyRenewalDate"
                            value={insuranceDetails.insurancePolicyRenewalDate}
                            onChange={handleInsuranceDetails}
                            // required
                            minDate={insuranceDetails.insurancePolicyStartDate}
                            // label="Policy Renewal Date"
                            disabled={state.isVehicleReceived}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <BookingDetails />
        </Grid>
    );
};

export default InsuranceDetails;
