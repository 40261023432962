import { InputLabel, Grid, Typography, ClickAwayListener, Paper, FormHelperText } from '@material-ui/core';
import './loanStyle.scss';
import { DatePicker, TextBox } from '../../../Core/FormInput';
import { useCallback, useContext, useEffect, useState } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';
import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function LoanVehicleDetails() {
    const { vehicleDetails, handleVehicleDetails, searchLoanVehicle, getRegNubmer, errors, state } = useContext(LoanVehicleContext);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleSearch = (val) => {
        if (val.length > 2 && state.isUpdate) {
            try {
                searchLoanVehicle(val);
                setShowDropdown(true);
            } catch (error) {
                console.error('Error searching vehicle:', error);
            }
        } else {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (vehicleDetails.vehicleReg) {
            handleSearch(vehicleDetails.vehicleReg);
        }
    }, [vehicleDetails.vehicleReg]);

    const handleVehicleSelect = (vehicle) => {
        try {
            setShowDropdown(false);
            getRegNubmer(vehicle);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <div className="Loan_Veh_heading">Loan Vehicle Details</div>
            </Grid>
            <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                <Grid item xs={12} sm={6} md={6} lg={3} style={{ position: 'relative' }}>
                    <InputLabel shrink error required>
                        Loan Vehicle Reg
                    </InputLabel>
                    <TextBox name="vehicleReg" value={vehicleDetails.vehicleReg} onChange={handleVehicleDetails} autoFocus disabled={state.isVehicleReceived} />
                    {errors.vehicleReg && <FormHelperText error>{errors.vehicleReg}</FormHelperText>}
                    {showDropdown && vehicleDetails.loanVehicleList?.length > 0 && (
                        <Paper
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                width: '100%',
                                maxHeight: '200px',
                                overflowY: 'auto',
                                marginTop: '2px'
                            }}
                        >
                            {vehicleDetails.loanVehicleList.map((vehicle) => (
                                <div
                                    key={vehicle.loanVehicleID}
                                    onClick={() => handleVehicleSelect(vehicle)}
                                    style={{
                                        textAlign: 'left',
                                        padding: '8px 16px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }}
                                >
                                    {vehicle.regNo}
                                </div>
                            ))}
                        </Paper>
                    )}
                </Grid>
                {/* ... other Grid items ... */}
            </ClickAwayListener>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <InputLabel shrink error required>
                    Vehicle model
                </InputLabel>
                <TextBox name="vehicleModel" value={vehicleDetails.vehicleModel} onChange={handleVehicleDetails} disabled={state.isVehicleReceived} />
                {errors.vehicleModel && <FormHelperText error>{errors.vehicleModel}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <InputLabel shrink>Insurance Value</InputLabel>
                <TextBox name="insuranceValue" value={vehicleDetails.insuranceValue} onChange={handleVehicleDetails} disabled={state.isVehicleReceived} />
                {/* {errors.insuranceValue && <FormHelperText error>{errors.insuranceValue}</FormHelperText>} */}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <InputLabel shrink style={{ marginBottom: '8px' }} error required>
                    Date
                </InputLabel>
                <DatePicker name="dateCreated" value={vehicleDetails.dateCreated} onChange={handleVehicleDetails} disabled={state.isVehicleReceived} />
                {errors.dateCreated && <FormHelperText error>{errors.dateCreated}</FormHelperText>}
            </Grid>
        </Grid>
    );
}

export default LoanVehicleDetails;
