import React, { useState, useEffect, useMemo, useContext } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';

import ManageAccountGrid from '../../../managementAccount/manageAccountGrid/index';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Expanse Code', active: true }
];
const basecolumns = [
    { field: 'expenseCode', title: 'Expense Code', width: 120 },
    { field: 'description001', title: 'Description' },
    { field: 'normalBalanceSign', title: 'Balance Sign', width: 120 },
    { field: 'accountingDivision', title: 'Account Division', width: 120 },
    { field: 'accountType', title: 'Type', width: 120 },
    { field: 'divisionDescription', title: 'Division', width: 120 },
    { field: 'expenseGroup', title: 'Group', width: 120 },
    { field: 'shortName', title: 'Branch', width: 120 }
];
const MappingExpenseCode = () => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });

    // const renderEdit = (ev, row) => {
    //     return (
    //         <div
    //             style={{
    //                 textAlign: 'center'
    //             }}
    //         >
    //             <EditButton
    //                 toolTipTitle="Update Category"
    //                 onClick={() => {
    //                     setState((st) => ({
    //                         ...st,
    //                         open: true,
    //                         title: 'Update Category',
    //                         selectedRowData: row
    //                         // isAdd: false
    //                     }));
    //                 }}
    //             />
    //         </div>
    //     );
    // };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        // let actionCol = tempCols.find((e) => e.field === 'action');
        // if (actionCol) {
        //     actionCol.renderer = renderEdit;
        // }
        return tempCols;
    }, []);

    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <ManageAccountGrid
                columns={columns}
                // getUrl={`DefectTypes`}
                getUrl={`NominalExpenseCodes/ExpenseCodes_List`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                // btnTitle="Add"
                // onBtnClick={addBtnClicked}
            />
        </div>
    );
};

export default MappingExpenseCode;
