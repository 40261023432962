import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, InputLabel, FormHelperText } from '@material-ui/core';
import { MandatoryField } from '../../../../Core/FormInput/inputfields';
import { SecondaryButton, SelectBox } from '../../../../Core/FormInput';
import AppContext from '../../../../App/AppContext';
import { getMAMapping_ScreenDropdowns, getMappingValues_GetDetails, postMappingValues_AddUpdate } from '../../../../Core/Service/MappingService';

function MappingSubModal(props) {
    const [state, setState] = useState({
        errors: {},
        mappingValueDescription: '',
        mappingValue: props.mappingValue || '',
        mappingValueCategoryID: '',
        mappingValueID: null
    });

    const [dropdowns, setDropdowns] = useState({
        categoryList: []
    });

    const [loading, setLoading] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const { showToast } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // const dropdownRes = await getMAMapping_ScreenDropdowns();
                // setDropdowns((prev) => ({
                //     ...prev,
                //     categoryList: dropdownRes.data.category || []
                // }));

                if (props.selectedRowData?.mappingValueID) {
                    const detailsRes = await getMappingValues_GetDetails(props.selectedRowData.mappingValueID);
                    if (detailsRes.success) {
                        const { mappingValueDescription, mappingValue, mappingValueCategoryID, mappingValueID } = detailsRes.data.list[0];
                        setState((prev) => ({
                            ...prev,
                            mappingValueDescription,
                            mappingValue,
                            mappingValueCategoryID,
                            mappingValueID
                        }));
                    }
                }
            } catch (error) {
                showToast(`Error fetching data: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [props.selectedRowData?.mappingValueID]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        const { mappingValueDescription } = state;
        const errors = {};
        const emptyStringPattern = /[\S]/;

        if (!emptyStringPattern.test(mappingValueDescription)) {
            errors.mappingValueDescription = 'This field is required';
        }

        setState((prev) => ({ ...prev, errors }));
        return Object.keys(errors).length === 0;
    };

    const submitHandler = async () => {
        if (!validateForm()) return;

        setBtnLoader(true);

        const data = {
            mappingValueID: state.mappingValueID,
            mappingValuePositionID: props.maMappingPositionsID,
            mappingValue: state.mappingValue,
            mappingValueDescription: state.mappingValueDescription,
            mappingValueCategoryID: state.mappingValueCategoryID
        };

        try {
            const res = await postMappingValues_AddUpdate(data);
            if (res.success) {
                showToast('Updated successfully');
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        } catch (error) {
            showToast(`Error: ${error.message}`);
        } finally {
            setBtnLoader(false);
        }
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    type="number"
                    inputLabel={props.mappingValueDescription ? props.mappingValueDescription + ' Value' : 'Value'}
                    name="mappingValue"
                    placeholder="Value"
                    onChange={handleInputChange}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, props.positionLength);
                    }}
                    value={state.mappingValue}
                    errors={state.errors.mappingValue}
                    disabled={props.positionLength == 'disabled'}
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel={props.mappingValueDescription ? props.mappingValueDescription + ' Description' : 'Description'}
                    name="mappingValueDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.mappingValueDescription}
                    errors={state.errors.mappingValueDescription}
                />
            </Grid>

            {/* <Grid item xs={12}>
                <InputLabel shrink>Category</InputLabel>
                <SelectBox
                    name="mappingValueCategoryID"
                    value={state.mappingValueCategoryID || ''}
                    onChange={handleInputChange}
                    List={dropdowns.categoryList}
                />
                <FormHelperText error>{state.errors.mappingValueCategoryID}</FormHelperText>
            </Grid> */}

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler} fullWidth>
                    {props.selectedRowData.mappingValueID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default MappingSubModal;
