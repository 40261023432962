import { InputLabel, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const SelectBox = (props) => {
    const { List, inputLabel, forceEditable, required, ...rest } = props;
    let insertEmpty = rest.insertEmpty;
    if (insertEmpty === undefined) {
        insertEmpty = true;
    }
    return (
        <>
            {inputLabel && (
                <InputLabel required={required} shrink>
                    {inputLabel}
                </InputLabel>
            )}

            <TextField select size="small" variant="outlined" {...rest} fullWidth>
                {insertEmpty && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {List.length > 0 &&
                    List.map((option) => (
                        <MenuItem key={option.id} value={option.id || ''}>
                            {option.name}
                        </MenuItem>
                    ))}
            </TextField>
        </>
    );
};

export default SelectBox;
