import { get, post } from './http-calls';

export let getLoanVehicleByID = async (id) => {
    let data = await get(`LoanVehicle/${id}`, { useAuthToken: true });
    return data;
};

export let addLoanVehicleScreenDropDown = async (id) => {
    let data = await get(`LoanVehicle/ScreenDropDowns`, { useAuthToken: true });
    return data;
};

export const postLoanVehicle = async (data) => {
    let res = await post(`LoanVehicle`, data, { useAuthToken: true });
    return res;
};