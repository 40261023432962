import React, { useEffect, useState, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';

import ManageAccountGrid from '../../../managementAccount/manageAccountGrid';
import { EditButton } from '../../../../Core/FormInput/inputfields';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import MappingSubModal from './mappingSubModal';

const basecolumns = [
    { field: 'action', title: 'Action', width: '7%' },
    { field: 'mappingValue', title: 'Mapping Value' },
    { field: 'description', title: 'Description' },
    { field: 'category', title: 'Category' }
];

function MappingListScreen(props) {
    const [state, setState] = useState({
        open: false
    });

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            tabsToggle: true,
                            selectedRowData: row
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.field === 'action');
        if (actionCol) {
            actionCol.renderer = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            tabsToggle: true,
            selectedRowData: {}
        }));
    };

    const closeDialog = (isReload) => {
        let newSt = {};
        newSt.tabsToggle = false;

        if (isReload) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    return (
        <div className="screen">
            <ManageAccountGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={`MappingValues?positionID=${props.positionID}`}
                btnTitle="Add Sub Mapping Value"
                onBtnClick={addBtnClicked}
                isReload={state.isReload}
            />
            {state.tabsToggle ? (
                <DialogComp
                    title={state.selectedRowData?.mappingValueID ? 'Update Mapping Data' : 'Add Mapping Data'}
                    onClose={() => closeDialog(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <MappingSubModal
                        onClose={closeDialog}
                        selectedRowData={state.selectedRowData}
                        maMappingPositionsID={props.positionID}
                        positionLength={props.positionLength}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default MappingListScreen;
