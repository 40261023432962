import React, { useState, useEffect, useMemo, useContext } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import AppContext from '../../../../App/AppContext';
import MappingCategoryModal from './mapperCategoryModal';
import { postMappingCategory } from '../../../../Core/Service/ManageAccount/manageAccountService';
import ManageAccountGrid from '../../../managementAccount/manageAccountGrid/index';
// ../managementAccount/manageAccountGrid/index';
import { EditButton } from '../../../../Core/FormInput/inputfields';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Mapping Categories', active: true }
];
const basecolumns = [
    { field: 'action', title: 'Action', width: 60 },
    { field: 'maMappingCategoryID', title: 'Code', width: 100 },
    { field: 'maMappingCategoryName', title: 'Description' }
];
const MapperCategoryScreen = () => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });

    const { showToast } = useContext(AppContext);

    const pullApiData = async () => {
        // /let res = await getAllDelayReason();
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Update Category"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: 'Update Category',
                            selectedRowData: row
                            // isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.field === 'action');
        if (actionCol) {
            actionCol.renderer = renderEdit;
        }
        return tempCols;
    }, []);

    const saveData = async (outData) => {
        let response = await postMappingCategory({
            mAMappingCategoryID: outData.id ?? null,
            mAMappingCategoryName: outData.value
        });
        if (response) {
            response.success ? showToast(response.message || 'updated successfully') : showToast(response.message || 'Something went wrong');
        } else {
            showToast('Something went wrong');
        }
        pullApiData();
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Category',
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false
        }));
    };
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <ManageAccountGrid
                columns={columns}
                // getUrl={`DefectTypes`}
                getUrl={`MappingCategory`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add"
                onBtnClick={addBtnClicked}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={handleClose} title={state.title} maxWidth="md">
                    <MappingCategoryModal data={state} onClose={handleClose} saveData={saveData} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default MapperCategoryScreen;
