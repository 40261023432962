import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BranchServiceContext } from './branchServicesProvider';
import formatters from './../../../../Core/Grid/inputFormatter';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    header: {
        background: '#2a4d90',
        color: 'white',
        padding: 5
    },
    body: {
        padding: 5
    }
});

export default function ServiceTableYTD() {
    const classes = useStyles();
    const { headerArray, sections } = useContext(BranchServiceContext);

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.header} colSpan={7} align="center">
                            Apr YTD
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {headerArray.map((k, i) => {
                            return (
                                i !== 0 && (
                                    <TableCell className={k && classes.header} align="right">
                                        {k}
                                    </TableCell>
                                )
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sections.map((row) => (
                        <React.Fragment>
                            <TableRow key={row.name}>
                                {row.titleHeader.map(
                                    (k, i) =>
                                        i !== 0 && (
                                            <TableCell className={k && classes.header} align={i === 0 ? 'left' : 'right'}>
                                                {k}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>

                            {row.list.map((obj) => (
                                <TableRow>
                                    {/* <TableCell className={classes.body} style={obj.style} align="left">
                                        {obj.name}
                                    </TableCell> */}
                                    <TableCell className={classes.body} style={obj.style} align="right">
                                        {formatters.CurrencyThousandSeparator(obj.actual)}
                                    </TableCell>
                                    <TableCell className={classes.body} style={obj.style} align="right">
                                        {formatters.CurrencyThousandSeparator(obj.budget)}
                                    </TableCell>
                                    <TableCell
                                        className={classes.body}
                                        style={{ ...obj.style, background: 'lightblue', color: obj.variance > 0 ? '' : 'red' }}
                                        align="right"
                                    >
                                        {obj.variance}
                                    </TableCell>
                                    <TableCell className={classes.body} style={{ ...obj.style, fontWeight: 600 }} align="right">
                                        {obj.kpi}
                                    </TableCell>
                                    <TableCell className={classes.body} style={obj.style} align="right">
                                        {obj.py}
                                    </TableCell>
                                    <TableCell className={classes.body} style={{ ...obj.style, background: 'lightblue' }} align="right">
                                        {obj.ly}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                {/* <TableCell className={classes.body}></TableCell> */}
                                <TableCell className={classes.body}></TableCell>
                                <TableCell className={classes.body}></TableCell>
                                <TableCell className={classes.body}></TableCell>
                                <TableCell className={classes.body}></TableCell>
                                <TableCell className={classes.body}></TableCell>
                                <TableCell className={classes.body}></TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
