import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TabView from '../../../../Core/Controls/Tabs';
import MappingAddEdit from './mappingAddEdit';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import { getMappingPositions } from '../../../../Core/Service/MappingService';
import MappingListScreen from './mappingListScreen';

const crumbs = [
    { name: 'Home', path: '/' },
    // { name: 'Vehicles', active: true },
    { name: 'Mapping', active: true }
];

const StandardLabourLineTabsScreen = () => {
    const [index, setIndex] = useState(undefined);

    const [state, setState] = useState({
        mappingTabList: [],
        lineID: null,
        selectedData: {},
        selectedPosition: []
    });

    useEffect(() => {
        pullTabData();
    }, []);

    const pullTabData = async () => {
        let res = await getMappingPositions();
        let tempList = [];
        if (res?.data?.list.length > 0)
            res?.data?.list.forEach((p) => {
                let tempList2 = p.maMappingPositionsValue.split(',').map((val) => +val.trim());
                tempList = [...tempList, ...tempList2];
            });
        setState((st) => ({ ...st, mappingTabList: res?.data?.list, selectedPosition: tempList || [] }));
    };

    const handleSection = (val) => {
        setState((st) => ({ ...st, tabsToggle: true, lineID: val?.lineID, selectedData: val }));
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, tabsToggle: false, lineID: null, selectedData: {} }));
        res && pullTabData();
    };

    const tabs = state.mappingTabList.map((a) => {
        return {
            label: (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'space-around', padding: 0, alignItems: 'center' }}>
                        <IconButton onClick={() => handleSection(a)}>
                            <EditIcon style={{ color: '#f50057', fontSize: 15 }} size="small" />
                        </IconButton>
                        {a.maMappingPositionsTitle}
                        {` (${a.maMappingPositionsValue})`}
                    </span>
                </div>
            ),
            body: <MappingListScreen positionID={a.maMappingPositionsID} positionLength={a.maMappingPositionsValue.split(',').length} />
        };
    });

    // tabs.push({
    //     id: null,
    //     label: <AddBoxIcon color="secondary" onClick={handleSection} style={{ cursor: 'pointer' }} />,
    //     accData: []
    // });

    return (
        <>
            <Grid container className="screen">
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs} />
                        </Grid>
                        <Grid item>
                            <AddBoxIcon color="secondary" onClick={handleSection} style={{ cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <TabView selectedIndex={index} tabList={tabs} id="VehicleContrctStatus_tabs" />
            </Grid>
            {state.tabsToggle ? (
                <DialogComp
                    title={state.selectedData?.maMappingPositionsID ? 'Update Mapping' : 'Add Mapping'}
                    onClose={() => closeDialog(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <MappingAddEdit
                        onClose={closeDialog}
                        selectedPosition={state.selectedPosition}
                        maMappingPositionsID={state.selectedData.maMappingPositionsID}
                    />
                </DialogComp>
            ) : null}
        </>
    );
};

export default StandardLabourLineTabsScreen;
