import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { MandatoryField } from '../../../../Core/FormInput/inputfields';
import { SecondaryButton } from '../../../../Core/FormInput';

const MappingCategoryModal = (props) => {
    const [state, setState] = useState({ id: null, value: '', errors: {}, isError: false, isError2: false });
    const { maMappingCategoryID, maMappingCategoryName } = props.data.selectedRowData;
    useEffect(() => {
        if (props.data) {
            setState((st) => ({
                ...st,
                id: maMappingCategoryID || null,
                value: maMappingCategoryName || ''
            }));
        }
    }, [props.data]);

    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!state.value) {
            isValid = false;
            errors.description = 'This field is required';
        }
        if (!state.id) {
            isValid = false;
            errors.code = 'This field is required';
        }
        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };
    const handleData = () => {
        if (validate()) {
            console.log('test');
            props.saveData(state);
            props.onClose();
        }
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Mapping Code"
                        placeholder="Mapping Code"
                        defaultValue={maMappingCategoryID ?? ''}
                        onChange={(event) => {
                            setState((st) => ({
                                ...st,
                                id: event.target.value
                            }));
                        }}
                        errors={state.errors.code}
                    />
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Description"
                        placeholder="Description"
                        defaultValue={maMappingCategoryName ?? ''}
                        onChange={(event) => {
                            setState((st) => ({
                                ...st,
                                value: event.target.value
                            }));
                        }}
                        errors={state.errors.description}
                    />
                </Grid>

                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton onClick={handleData} fullWidth>
                        {props.data.isAdd ? 'ADD' : 'UPDATE'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default MappingCategoryModal;
