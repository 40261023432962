import { useContext } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';
import { InputLabel, Grid, Typography, FormHelperText, Hidden } from '@material-ui/core';
import { TextBox, DatePicker, DateTimePicker } from '../../../Core/FormInput';

const BookingDetails = () => {
    const { bookingDetails, handleBookingDetails, errors, state } = useContext(LoanVehicleContext);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className="Loan_Veh_heading">Booking Details</div>
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }} required error>
                        Date Out
                    </InputLabel>
                    <DateTimePicker name="dateOut" value={bookingDetails.dateOut} onChange={handleBookingDetails} required disabled={state.isVehicleReceived} />
                    {errors.dateOut && <FormHelperText error>{errors.dateOut}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Mileage Out
                    </InputLabel>
                    <TextBox
                        name="mileageOut"
                        value={bookingDetails.mileageOut}
                        onChange={handleBookingDetails}
                        required
                        type="number"
                        min={0}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.mileageOut && <FormHelperText error>{errors.mileageOut}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Fuel Level Out
                    </InputLabel>
                    <TextBox
                        name="fuelOut"
                        value={bookingDetails.fuelOut}
                        onChange={handleBookingDetails}
                        required
                        type="number"
                        disabled={state.isVehicleReceived}
                        min={0}
                        max={100}
                    />
                    {errors.fuelOut && <FormHelperText error>{errors.fuelOut}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }}>
                        Estimated Due Date
                    </InputLabel>
                    <DateTimePicker
                        name="estimatedDateDueIn"
                        value={bookingDetails.estimatedDateDueIn}
                        onChange={handleBookingDetails}
                        required
                        minDate={bookingDetails.dateOut}
                        disabled={state.isVehicleReceived}
                    />
                    {/* {errors.estimatedDateDueIn && <FormHelperText error>{errors.estimatedDateDueIn}</FormHelperText>} */}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }} required error>
                        Date In
                    </InputLabel>
                    <DateTimePicker
                        name="dateIn"
                        value={bookingDetails.dateIn}
                        onChange={handleBookingDetails}
                        minDate={bookingDetails.dateOut}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.dateIn && <FormHelperText error>{errors.dateIn}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Mileage In
                    </InputLabel>
                    <TextBox
                        name="mileageIn"
                        value={bookingDetails.mileageIn}
                        onChange={handleBookingDetails}
                        type="number"
                        min={bookingDetails.mileageOut || 0}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.mileageIn && <FormHelperText error>{errors.mileageIn}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Fuel Level In
                    </InputLabel>
                    <TextBox
                        name="fuelIn"
                        value={bookingDetails.fuelIn}
                        onChange={handleBookingDetails}
                        type="number"
                        min={0}
                        max={100}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.fuelIn && <FormHelperText error>{errors.fuelIn}</FormHelperText>}
                </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
                <Grid item xs={12} sm={4} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }} required error>
                        Date Out
                    </InputLabel>
                    <DateTimePicker name="dateOut" value={bookingDetails.dateOut} onChange={handleBookingDetails} required disabled={state.isVehicleReceived} />
                    {errors.dateOut && <FormHelperText error>{errors.dateOut}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sm={4} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Mileage Out
                    </InputLabel>
                    <TextBox
                        name="mileageOut"
                        value={bookingDetails.mileageOut}
                        onChange={handleBookingDetails}
                        required
                        type="number"
                        min={0}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.mileageOut && <FormHelperText error>{errors.mileageOut}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Fuel Level Out
                    </InputLabel>
                    <TextBox
                        name="fuelOut"
                        value={bookingDetails.fuelOut}
                        onChange={handleBookingDetails}
                        required
                        type="number"
                        min={0}
                        max={100}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.fuelOut && <FormHelperText error>{errors.fuelOut}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }} required error>
                        Date In
                    </InputLabel>
                    <DateTimePicker
                        name="dateIn"
                        value={bookingDetails.dateIn}
                        onChange={handleBookingDetails}
                        minDate={bookingDetails.dateOut}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.dateIn && <FormHelperText error>{errors.dateIn}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sm={4} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Mileage In
                    </InputLabel>
                    <TextBox
                        name="mileageIn"
                        value={bookingDetails.mileageIn}
                        onChange={handleBookingDetails}
                        type="number"
                        min={bookingDetails.mileageOut || 0}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.mileageIn && <FormHelperText error>{errors.mileageIn}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={2}>
                    <InputLabel shrink component="legend" required error>
                        Fuel Level In
                    </InputLabel>
                    <TextBox
                        name="fuelIn"
                        value={bookingDetails.fuelIn}
                        onChange={handleBookingDetails}
                        type="number"
                        min={0}
                        max={100}
                        disabled={state.isVehicleReceived}
                    />
                    {errors.fuelIn && <FormHelperText error>{errors.fuelIn}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={4}>
                    <InputLabel shrink component="legend" style={{ marginBottom: 8 }}>
                        Estimated Due Date
                    </InputLabel>
                    <DateTimePicker
                        name="estimatedDateDueIn"
                        value={bookingDetails.estimatedDateDueIn}
                        onChange={handleBookingDetails}
                        required
                        minDate={bookingDetails.dateOut}
                        disabled={state.isVehicleReceived}
                    />
                    {/* {errors.estimatedDateDueIn && <FormHelperText error>{errors.estimatedDateDueIn}</FormHelperText>} */}
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={6} lg={4}></Grid>

            <Grid item xs={4} style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black', padding: '7px', textAlign: 'right' }}>
                Total Mileage covered
            </Grid>
            <Grid item xs={2} style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black', padding: '7px', textAlign: 'left' }}>
                <b>{(bookingDetails.mileageIn && bookingDetails.mileageIn - bookingDetails.mileageOut) || ''}</b>
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '7px', textAlign: 'left' }}>
                Total Fuel Used:{' '}
                <b>
                    {bookingDetails.fuelIn != null && bookingDetails.fuelOut != null ? Math.abs(bookingDetails.fuelIn - bookingDetails.fuelOut).toFixed(2) : ''}
                </b>
            </Grid>
        </Grid>
    );
};

export default BookingDetails;
