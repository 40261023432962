import { get, post } from './http-calls';

export const getMappingPositions = async () => {
    let res = await get(`MappingPositions`, { useAuthToken: true });
    return res;
};

export const postMappingPositions_AddUpdate = async (data) => {
    let res = await post('MappingPositions/MappingPositions_AddUpdate', data, { useAuthToken: true });
    return res;
};

export const getMappingPositions_GetDetails = async (ID) => {
    let res = await get(`MappingPositions/MappingPositions_GetDetails/${ID}`, { useAuthToken: true });
    return res;
};

export const postMappingValues_AddUpdate = async (data) => {
    let res = await post('MappingValues/MappingValues_AddUpdate', data, { useAuthToken: true });
    return res;
};

export const getMappingValues_GetDetails = async (ID) => {
    let res = await get(`MappingValues/MappingValues_GetDetails/${ID}`, { useAuthToken: true });
    return res;
};

export const getMAMapping_ScreenDropdowns = async (ID) => {
    let res = await get(`MappingValues/MAMapping_ScreenDropdowns`, { useAuthToken: true });
    return res;
};

export const getMAMappingCategory_Search = async (ID) => {
    let res = await get(`NominalCodes/MAMappingCategory_Search?searchTerm=`, { useAuthToken: true });
    return res;
};

export const postMANominalCategory_AddUpdate = async (data) => {
    let res = await post('NominalCodes/MANominalCategory_AddUpdate', data, { useAuthToken: true });
    return res;
};
