import { get, post, put } from './http-calls';

export const getByWorkflowIdAndHeaderId = async (workflowId, headerId) => {
    let res = await get(`ReturnReasons/ByWorkflowId/${workflowId}${headerId ? `/${headerId}` : ''}`, { useAuthToken: true });
    return res;
};

// public long JobId { get; set; }
// public int ReturnReasonId { get; set; }
// public string Message { get; set; }
// public bool? returnSelect { get; set; }
// public long? TechnicianJobID { get; set; }
export const processsReturnReasonForJob = async (jobId, returnReasonId, anyNotes, selectedTechnician, resetSignature, AssignedUserID) => {
    let res = await post(
        `ReturnReasons/ProcessForJob`,
        {
            JobId: jobId,
            ReturnReasonId: returnReasonId,
            message: anyNotes,
            TechnicianJobID: selectedTechnician,
            resetSignature: resetSignature,
            AssignedUserID: selectedTechnician ? '' : AssignedUserID
        },
        { useAuthToken: true }
    );
    return res;
};

export const addUpdateReturnReason = async (
    id,
    name,
    workflowID,
    nextWorkflow,
    isActive,
    UniqIdentifier,
    backgroundColorCode,
    textColorCode,
    checkMandatorySteps,
    returnReasonNotificationList
) => {
    let res = await post(
        `ReturnReasons`,
        {
            ReturnReasonID: id,
            ReturnReasonDescription: name,
            ReturnReasonWorkflowID: workflowID,
            ReturnReasonNextWorkflowID: nextWorkflow,
            ReturnReasonActive: isActive,
            UniqIdentifier: UniqIdentifier,
            returnReasonColourCode: backgroundColorCode,
            returnReasonTextColourCode: textColorCode,
            checkMandatorySteps: checkMandatorySteps,
            returnReasonNotificationList: returnReasonNotificationList
        },
        { useAuthToken: true }
    );
    return res;
};

export const processsReturnReasonForNewUSedStock = async (StockID, returnReasonId, anyNotes, selectedTechnician, resetSignature, isNew = true) => {
    let res = await post(
        `ReturnReasons/Stock/ProcessForJob?isNew=${isNew}`,
        {
            StockID: StockID,
            ReturnReasonId: returnReasonId,
            message: anyNotes,
            TechnicianJobID: selectedTechnician,
            resetSignature: resetSignature
        },
        { useAuthToken: true }
    );
    return res;
};

export const getByWorkflowIdAndStockID = async (workflowId, stockID, isNew = true) => {
    let res = await get(`ReturnReasons/Stock/ByWorkflowId/${workflowId}${stockID ? `/${stockID}` : ''}?isNew=${isNew}`, { useAuthToken: true });
    return res;
};

export const ReturnReason_GetBranchEmailList = async (id) => {
    let res = await get(`ReturnReasons/ReturnReason_GetNotificationDetails?${id ? `ReturnReasonId=${id}` : ''}`, { useAuthToken: true });
    return res;
};
