import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import { Navbar } from './../../Navbar';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ForumIcon from '@material-ui/icons/Forum';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import BuildIcon from '@material-ui/icons/Build';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';

export default [
    {
        label: 'Home',
        path: '/',
        exact: true,
        component: Components.home,
        authType: TypeOfAuth.Auth,
        icon: <HomeIcon />
    },

    {
        label: 'Branches',
        path: '/branches',
        exact: true,
        component: Components.Braches,
        authType: TypeOfAuth.Auth,
        icon: <BuildIcon />
    },
    {
        label: 'Users',
        path: '/users',
        component: Components.userManagement,
        authType: TypeOfAuth.Auth,
        icon: <PersonIcon />
    },
    {
        label: 'eWIP',
        authType: TypeOfAuth.Both,
        icon: <LoyaltyIcon />,
        children: [
            {
                label: 'Workflows',
                path: '/workflow',
                component: Components.workflow,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Web Forms',
                path: '/webform',
                component: Components.WebForm,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'OCR Documents',
                path: '/ocrdocuments',
                component: Components.ocrDocuments,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Diagnostic Sheets',
                authType: TypeOfAuth.Both,
                children: [
                    { label: 'Faults', path: '/diagnostic_faults', component: Components.DiagnosticFaults, authType: TypeOfAuth.Auth },
                    {
                        label: 'Engine Faults',
                        path: '/diagnosticEngineFaults',
                        component: () => <Components.DiagnosticEngineFaults screenName={'Diagnostic Engine Faults'} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Noise Apparents',
                        path: '/diagnosticNoiseApparents',
                        component: () => <Components.DiagnosticEngineFaults screenName={'Diagnostic Noise Apparents'} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Locations',
                        path: '/diagnosticLocations',
                        component: () => <Components.DiagnosticEngineFaults screenName={'Diagnostic Locations'} />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Lists',
                path: '/',
                authType: TypeOfAuth.Both,
                children: [
                    {
                        label: 'Failure Notes',
                        path: '/Lists/failurenotes',
                        exact: true,
                        component: Components.FailureNotes,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Std Tech Write-ups',
                        path: '/standard-write-up',
                        component: Components.StandardTechWriteUp,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work Accomplished Codes',
                        path: '/Lists/workaccomplishedcode',
                        exact: true,
                        component: Components.WorkAccomplishedCode,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Workflow Steps List',
                        path: '/Lists/workflowttepslist',
                        exact: true,
                        component: Components.WorkflowStepsList,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Battery Results',
                        path: '/Lists/BatteryResults',
                        exact: true,
                        component: Components.BatteryResults,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Defect Reported Categories',
                        path: '/defect-reported-categories',
                        component: Components.DefectReportedCategories,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Defect Actioned Category',
                        path: '/DefectActionCategories',
                        component: Components.DefectActionedCategories,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Battery Manufacturers',
                        path: '/Lists/BatteryManufacturers',
                        exact: true,
                        component: Components.BatteryManufacturers,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Video Categories',
                        path: '/Lists/VideoCategories',
                        exact: true,
                        component: Components.VideoCategories,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Videos',
                        path: '/Lists/Videos',
                        exact: true,
                        component: Components.Videos,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'VOR Types',
                        exact: true,
                        path: '/Communicator/vortypes',
                        component: Components.VorTypes,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Operators',
                        exact: true,
                        path: '/operators',
                        component: Components.Operators,
                        authType: TypeOfAuth.Auth
                    },
                    { label: 'Parts', exact: true, path: '/Parts', component: Components.PartsList, authType: TypeOfAuth.Auth }
                ]
            },
            {
                label: 'QC',
                path: '/',
                authType: TypeOfAuth.Both,
                children: [
                    {
                        label: 'Results Lists',
                        path: '/results-lists',
                        component: Components.QCResultsList,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Items',
                        path: '/items',
                        component: Components.QCItems,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Documents',
                path: '/',
                authType: TypeOfAuth.Both,
                children: [
                    {
                        label: 'Re-Process Documents',
                        path: '/reprocessdocuments',
                        exact: true,
                        component: Components.DocumentsReprocess,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Translations',
                path: '/Translations',
                exact: true,
                component: Components.Translations,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Dynamic Forms',
                path: '/DynamicFormsList',
                component: Components.DynamicFormsList,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Inspection Checks',
                path: '/vehicle/VehicleCheckChecks',
                exact: true,
                component: Components.VehicleCheckChecks,
                authType: TypeOfAuth.Auth
            }
        ]
    },
    {
        label: 'Communicator',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ForumIcon />,
        children: [
            {
                label: 'Branch Parameters',
                exact: true,
                path: '/Communicator/BranchParameters',
                component: () => <Components.BranchParametersScreen />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Booking Request Status',
                exact: true,
                path: '/Communicator/BookingRequestStatus',
                component: () => <Components.BookingRequestStatus />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Notifications Groups',
                exact: true,
                path: '/Communicator/setVehicleNotification',
                component: Components.AdminVehicleNotification,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Vehicle Status Update',
                exact: true,
                path: '/Communicator/smsstatusupdate',
                component: Components.SMSStatusUpdate,
                authType: TypeOfAuth.Auth
            }
        ]
    },
    {
        label: 'Reporting',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            {
                label: 'Aged Measures',
                exact: true,
                path: '/report/ageMeasured',
                component: () => <Components.AdminReportingAgeMeasured />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Cash Account Categories',
                exact: true,
                path: '/reporting/CashAccountCategories',
                component: Components.CashAccountCategories,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Cash Account Types',
                exact: true,
                path: '/reporting/CashAccountTypes',
                component: Components.CashAccountTypes,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Holiday',
                path: '/HolidayCalenderComp',
                icon: <LabelImportantIcon />,
                exact: true,
                component: Components.HolidayCalenderComp,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Reporting Codes',
                path: '/Reporting/ReportingCodes',
                exact: true,
                component: Components.ReportingCodes,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Department Codes',
                path: '/Reporting/DepartmentCodes',
                exact: true,
                component: Components.DepartmentCodes,
                authType: TypeOfAuth.Auth
            }
            //
            // {
            //     label: 'Branches',
            //     exact: true,
            //     path: '/invoice/Branches',
            //     component: () => <Components.BranchesScreen />,
            //     authType: TypeOfAuth.Auth
            // },
            // {
            //     label: 'Branch Status Email',
            //     exact: true,
            //     path: '/invoice/BranchStatusEmail',
            //     component: () => <Components.BranchStatusEmail />,
            //     authType: TypeOfAuth.Auth
            // }
        ]
    },
    {
        label: 'Invoices',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            {
                label: 'Customers',
                exact: true,
                path: '/invoice/Customers',
                component: () => <Components.InvoiceCustomersListScreen />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Branches',
                exact: true,
                path: '/invoice/Branches',
                component: () => <Components.BranchesScreen />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Branch Status Email',
                exact: true,
                path: '/invoice/BranchStatusEmail',
                component: () => <Components.BranchStatusEmail />,
                authType: TypeOfAuth.Auth
            }
        ]
    },
    {
        label: 'Appraisal',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            {
                label: 'Create Appraisal Screen Columns',
                exact: true,
                path: '/Appraisal/Create_Appraisal_Screens_Columns',
                component: Components.AppraisalScreensColumns,
                authType: TypeOfAuth.Auth
            }
        ]
    },

    {
        label: 'OCR Documents To Process',
        path: '/ocrdocumentstoprocess/:name/:ocrDocId',
        hidden: true,
        exact: true,
        component: Components.OcrDocumentToProcess,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'OCR Documents Field',
        path: '/ocrdocumentsfield/:ocrDocId',
        hidden: true,
        exact: true,
        component: Components.OcrDocumentField,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Settings',
        path: '/Settings',
        icon: <SettingsIcon />,
        exact: true,
        component: Components.Settings,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Ticker Messages',
        path: '/ticker_Messages',
        icon: <LabelImportantIcon />,
        exact: true,
        component: Components.KipMessages,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Workflow Steps List Option',
        path: '/workflowstplistops/:listID',
        component: Components.WorkflowStepsListOptions,
        authType: TypeOfAuth.Auth,
        hidden: true,
        exact: true
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Workflow Steps',
        hidden: true,
        exact: true,
        path: '/steps/:workflowId',
        component: Components.workflowSteps,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Return Reason',
        hidden: true,
        exact: true,
        path: '/returnreason/:WorkflowName/:workflowId',
        component: Components.ReturnReason,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Dynamic Form Fields',
        hidden: true,
        exact: true,
        path: '/DynamicForm/:DynamicFormID',
        component: Components.DynamicFormFields,
        authType: TypeOfAuth.Auth
    }
];
