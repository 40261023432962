import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormLabel, Grid, Typography, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { SelectBox, TextBox } from '../../../Core/FormInput';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb/index';
import { addLoanVehicleScreenDropDown, getLoanVehicleByID, postLoanVehicle } from '../../../Core/Service/loan-vehicle-service';
import moment from 'moment';
import { useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, PrimaryButton } from '../../../Core/FormInput/AppButton';
import { get } from 'lodash';
import DialogComp from '../../../Core/Modal/dialogModal';

const crumbs = (vehicleIDparam) => {
    return [
        { name: 'Home', path: '/' },
        { name: 'Loan Vehicles', path: '/loanVehicle' },
        { name: `${vehicleIDparam ? 'Update Loan Vehicle' : 'Add Loan Vehicle'}`, active: true }
    ];
};

const AddUpdateLoanVehicle = (props) => {
    const history = useHistory();
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        vehicleCategories: [],
        vehicleTypes: [],
        vehicleOdometerUnits: [],
        fuelTypes: [],
        branches: [],
        vehicleActive: false,
        vehicleFuelTypeID: '',
        vehicleOdometerUnitCode: '',
        errors: {}
    });

    const vehicleIDparam = props?.location?.state?.vehicleData || null;
    useEffect(() => {
        const fetchVehicleDetails = async () => {
            if (vehicleIDparam) {
                let res = await getLoanVehicleByID(vehicleIDparam);
                let data = res?.data?.detail?.table[0];
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        vehicleId: data.vehicleId,
                        vehicleFuelType: data.vehicleFuelType,
                        vehicleOdometerUnitCode: data.vehicleOdometerUnitCode,
                        vehicleLastMileageDate: data.vehicleLastMileageDate ? moment(data.vehicleLastMileageDate).format('YYYY-MM-DD') : '',
                        vehicleActive: data.vehicleActive || false,
                        vehicleRegNumber: data.vehicleRegNumber,
                        vehicleBranchID: data.vehicleBranchID,
                        vehicleTypeID: data.vehicleTypeID,
                        vehicleCategoryID: data.vehicleCategoryID,
                        vehicleFuelTypeID: data.vehicleFuelTypeID,
                        vehicleAssetNumber: data.vehicleAssetNumber,
                        vehicleMake: data.vehicleMake,
                        vehicleModel: data.vehicleModel,
                        vehicleColour: data.vehicleColour,
                        vehicleCO2Emission: data.vehicleCO2Emission,
                        vehicleMPG: data.vehicleMPG,
                        vehicleTaxDue: data.vehicleTaxDue ? moment(data.vehicleTaxDue).format('YYYY-MM-DD') : '',
                        vehicleDateOfRegistration: data.vehicleDateOfRegistration ? moment(data.vehicleDateOfRegistration).format('YYYY-MM-DD') : '',
                        vehicleMOTExpiry: data.vehicleMOTExpiry ? moment(data.vehicleMOTExpiry).format('YYYY-MM-DD') : '',
                        vehicleChassisNumber: data.vehicleChassisNumber,
                        vehicleLatestMileage: data.vehicleLatestMileage,
                        vehicleGrossWeight: data.vehicleGrossWeight
                    }));
                }
            }
        };
        fetchVehicleDetails();
    }, [vehicleIDparam]);

    useEffect(() => {
        const fetchDropdownData = async () => {
            let res = await addLoanVehicleScreenDropDown();
            if (res.success) {
                setState((st) => ({
                    ...st,
                    vehicleCategories: res.data?.vehicleCategories || [],
                    vehicleTypes: res.data?.vehicleTypes || [],
                    vehicleOdometerUnits: res.data?.vehicleOdometerUnits || [],
                    fuelTypes: res.data?.fuelTypes || [],
                    branches: res.data?.branches || []
                }));
            }
        };
        fetchDropdownData();
    }, []);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        const mandatoryFields = [
            { name: 'vehicleRegNumber', displayName: 'Registration Number' },
            { name: 'vehicleBranchID', displayName: 'Branch' },
            { name: 'vehicleMake', displayName: 'Make' },
            { name: 'vehicleModel', displayName: 'Model' },
            { name: 'vehicleMPG', displayName: 'MPG' },
            { name: 'vehicleOdometerUnitCode', displayName: 'Odometer Unit' },
            { name: 'vehicleFuelTypeID', displayName: 'Fuel Type' }
        ];

        let missingFields = [];

        for (const { name, displayName } of mandatoryFields) {
            const fieldValue = getField(name);
            if (!fieldValue) {
                errors[name] = `${displayName} is required`;
                formIsValid = false;
                missingFields.push(displayName);
            }
        }

        setState((st) => ({ ...st, errors, missingFields }));

        if (!formIsValid) {
            setState((st) => ({ ...st, showModal: true }));
        }

        return formIsValid;
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            let data = {
                vehicleId: vehicleIDparam || state.vehicleId || null,
                vehicleFuelType: state.vehicleFuelType,
                vehicleOdometerUnitCode: state.vehicleOdometerUnitCode,
                vehicleLastMileageDate: state.vehicleLastMileageDate,
                vehicleActive: state.vehicleActive || false,
                vehicleRegNumber: state.vehicleRegNumber,
                vehicleBranchID: state.vehicleBranchID,
                vehicleTypeID: state.vehicleTypeID,
                vehicleCategoryID: state.vehicleCategoryID,
                vehicleFuelTypeID: state.vehicleFuelTypeID,
                vehicleAssetNumber: state.vehicleAssetNumber,
                vehicleMake: state.vehicleMake,
                vehicleModel: state.vehicleModel,
                vehicleColour: state.vehicleColour,
                vehicleCO2Emission: state.vehicleCO2Emission,
                vehicleMPG: state.vehicleMPG,
                vehicleTaxDue: state.vehicleTaxDue || '',
                vehicleDateOfRegistration: state.vehicleDateOfRegistration,
                vehicleMOTExpiry: state.vehicleMOTExpiry,
                vehicleChassisNumber: state.vehicleChassisNumber,
                vehicleLatestMileage: state.vehicleLatestMileage,
                vehicleGrossWeight: state.vehicleGrossWeight
            };

            let res = await postLoanVehicle(data);
             if (res.success) {
                showToast(`Loan Vehicle ${data.vehicleId ? 'Updated' : 'Added'} Successfully`);
                history.push('/loanVehicle');
            } else {
                showToast(`Failed to ${data.vehicleId ? 'Update' : 'Add'} Loan Vehicle`);
            }
        }
    };

    const handleCancel = () => {
        history.push('/loanVehicle');
    };

    const getField = (name) => {
        return get(state, name);
    };
    const setField = (e) => {
        const { name, checked } = e.target;
        setState((st) => ({ ...st, [name]: checked }));
    };
    const handleClose = () => {
        setState((st) => ({ ...st, showModal: false, missingFields: [] }));
    };

    return (
        <>
            <div style={{ padding: '10px 20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginLeft: '-15px' }}>
                        <BreadCrumbs crumbs={crumbs(vehicleIDparam)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Registration Number
                                </FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleRegNumber} name="vehicleRegNumber" />
                                <FormHelperText error>{state.errors.vehicleRegNumber}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Branch
                                </FormLabel>
                                <SelectBox
                                    onChange={handleFieldChange}
                                    value={+state.vehicleBranchID}
                                    name="vehicleBranchID"
                                    List={state.branches || []}
                                    style={{ marginTop: 8, textAlign: 'left' }}
                                />
                                <FormHelperText error>{state.errors.vehicleBranchID}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Make
                                </FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleMake} name="vehicleMake" />
                                <FormHelperText error>{state.errors.vehicleMake}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Model
                                </FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleModel} name="vehicleModel" />
                                <FormHelperText error>{state.errors.vehicleModel}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Fuel Type
                                </FormLabel>
                                <SelectBox
                                    onChange={handleFieldChange}
                                    value={state.vehicleFuelTypeID}
                                    name="vehicleFuelTypeID"
                                    List={state.fuelTypes || []}
                                    style={{ marginTop: 8, textAlign: 'left' }}
                                />
                                <FormHelperText error>{state.errors.vehicleFuelTypeID}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Odometer Unit
                                </FormLabel>
                                <SelectBox
                                    onChange={handleFieldChange}
                                    value={state.vehicleOdometerUnitCode}
                                    name="vehicleOdometerUnitCode"
                                    List={state.vehicleOdometerUnits || []}
                                    style={{ marginTop: 8, textAlign: 'left' }}
                                />
                                <FormHelperText error>{state.errors.vehicleOdometerUnitCode}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend" className="mandatoryfields">
                                    MPG
                                </FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleMPG} name="vehicleMPG" />
                                <FormHelperText error>{state.errors.vehicleMPG}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">CO2 Emission</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleCO2Emission} name="vehicleCO2Emission" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Vehicle Category</FormLabel>
                                <SelectBox
                                    onChange={handleFieldChange}
                                    value={+state.vehicleCategoryID}
                                    name="vehicleCategoryID"
                                    List={state.vehicleCategories || []}
                                    style={{ marginTop: 8, textAlign: 'left' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Vehicle Type</FormLabel>
                                <SelectBox
                                    onChange={handleFieldChange}
                                    value={+state.vehicleTypeID}
                                    name="vehicleTypeID"
                                    List={state.vehicleTypes || []}
                                    style={{ marginTop: 8, textAlign: 'left' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Colour</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleColour} name="vehicleColour" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Gross Weight</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleGrossWeight} name="vehicleGrossWeight" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Asset Number</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleAssetNumber} name="vehicleAssetNumber" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Chassis Number</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleChassisNumber} name="vehicleChassisNumber" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Last Mileage Date</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleLastMileageDate} name="vehicleLastMileageDate" type="date" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Latest Mileage</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleLatestMileage} name="vehicleLatestMileage" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Date of Registration</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleDateOfRegistration} name="vehicleDateOfRegistration" type="date" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">MOT Expiry</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleMOTExpiry} name="vehicleMOTExpiry" type="date" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormLabel component="legend">Tax Due</FormLabel>
                                <TextBox onChange={handleFieldChange} value={state.vehicleTaxDue} name="vehicleTaxDue" type="date" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <FormControlLabel
                                    control={<Checkbox checked={state.vehicleActive} onChange={setField} name="vehicleActive" color="primary" />}
                                    label="Is Active?"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', paddingTop: 10 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PrimaryButton fullWidth onClick={handleSubmit}>
                            Submit
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SecondaryButton fullWidth onClick={handleCancel}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </div>
            {state.showModal ? (
                <DialogComp title="Required Fields" onClose={handleClose}>
                    <div style={{ color: 'red', paddingBottom: '20px' }}>
                        {state.missingFields.join(', ')} {state.missingFields.length > 1 ? 'are' : 'is'} required.
                    </div>
                </DialogComp>
            ) : null}
        </>
    );
};

export default AddUpdateLoanVehicle;
