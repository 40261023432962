import React, { useState, useEffect, useMemo } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import formatters from '../../../../Core/Grid/inputFormatter';
import ReactDOM from 'react-dom';
import MananageAccountGrid from '../../../managementAccount/manageAccountGrid';
import NominalTransInvoice from '../NominalTransaction/nominalTransInvoice';
import { Grid, InputLabel } from '@material-ui/core';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import AccountCodesHistory from '../AccountCodesOverview/AccountCodeHistory';
import { getMA_GetNominalTransactionDropdowns, getMANominalTransaction_List } from '../../../../Core/Service/ManageAccount/manageAccountService';
import { DatePicker, Multiselect, SingelSelect } from '../../../../Core/FormInput';
import moment from 'moment';
const columns = [
    // { field: 'accountCode', title: 'Account Code', width: 130 },
    { field: 'postingDate', title: 'Posting Date', renderer: formatters.DateFormatter, width: 110 },
    { field: 'documentDate', title: 'Document Date', renderer: formatters.DateFormatter, width: 110 },
    { field: 'documentNumber', title: 'Document Number', width: 130 },
    { field: 'source', title: 'Source', width: 60 },
    { field: 'journalReference', title: 'Reference', width: 110 },
    { field: 'narrative', title: 'Narrative', width: 250 },
    { field: 'baseValue', title: 'Value', width: 90, renderer: formatters.CurrencyThousandSeparator, align: 'right' }
    // { field: 'division', title: 'Division', width: 150 },
    // { field: 'costCentreName', title: 'Cost Centre', width: 250 },
    // { field: 'expenseName', title: 'Expense Name', width: 250 },
    // { field: 'branch', title: 'Branch Name', width: 100 },
    // { field: 'department', title: 'Department Name', width: 150 },
    // { field: 'model', title: 'Model', width: 150 },
    // { field: 'period', title: 'Period', width: 60 },
    // { field: 'year', title: 'Year', width: 60 }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Nominal Transactions', active: true }
];

const NominalTransaction = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamAccountCode = params.get('accountCode') || '';

    const [gridUrl, setGridUrl] = useState(`NominalTransaction/MANominalTransaction_List`);
    const [modalOpen, setModalOpen] = useState(false);
    const [invoiceFileName, setInvoiceFileName] = useState(null);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [AccountCode, setAccountCode] = useState(false);

    const [state, setState] = useState({
        startDate: null,
        endDate: null,
        costCentreIds: [],
        departmentCodeIds: [],
        divisionsIds: [],
        expenseCodeIds: [],
        expenseGroupIds: [],
        mappingCategoryIds: []
    });
    const [list, setList] = useState({
        costCentreList: [],
        departmentCodeList: [],
        divisionsList: [],
        expenseCodeList: [],
        expenseGroupList: [],
        mappingCategoryList: []
    });

    const [updatedList, setUpdatedList] = useState({
        filteredCostCentre: [],
        filteredExpenseCode: []
    });

    const [isReload, setIsReload] = useState(null);

    useEffect(() => {
        try {
            if (state.departmentCodeIds?.length > 0 && list.costCentreList?.length > 0) {
                const filteredCostCentre = list.costCentreList.filter((c) => state.departmentCodeIds.some((d) => d === c.costCentreGroup));
                setUpdatedList((st) => ({ ...st, filteredCostCentre: filteredCostCentre }));
            } else {
                console.warn('departmentCode or costCentre List is empty or undefined');
            }
        } catch (error) {
            console.error('An error occurred :', error);
        }
    }, [state.departmentCodeIds, list.costCentreList]);

    // Filter Expense category

    useEffect(() => {
        try {
            if (state.expenseGroupIds?.length > 0 && list.expenseCodeList?.length > 0) {
                const filteredExpenseCode = list.expenseCodeList.filter((c) => state.expenseGroupIds.some((d) => d === c.expenseGroup));
                setUpdatedList((st) => ({ ...st, filteredExpenseCode: filteredExpenseCode }));
            } else {
                console.warn('expenseGroupIds or expenseCodeList List is empty or undefined');
            }
        } catch (error) {
            console.error('An error occurred :', error);
        }
    }, [state.expenseGroupIds, list.expenseCodeList]);

    const handleOpenModal = async (fileName) => {
        setInvoiceFileName(fileName);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setHistoryModalOpen(false);
        setInvoiceFileName(null);
    };

    const handleHistoryModal = async (fileName) => {
        setAccountCode(fileName);
        setHistoryModalOpen(true);
    };

    useEffect(() => {
        if (ParamAccountCode || props.AccountCode) {
            try {
                setGridUrl(`NominalTransaction/MANominalTransaction_List?accountCode=${ParamAccountCode || props.AccountCode}`);
            } catch (err) {
                console.error(err);
            }
        }
    }, [ParamAccountCode, props.AccountCode]);

    const queryStringFromArray = (params) => {
        try {
            return Object.entries(params)
                .filter(([_, values]) => Array.isArray(values) && values.length > 0)
                .map(([key, values]) => `${key}=${values.join(`&${key}=`)}`)
                .join('&');
        } catch (error) {
            console.error('Error while building query string from array:', error);
            return '';
        }
    };

    useEffect(() => {
        try {
            const baseUrl = props.AccountCode?`NominalTransaction/MANominalTransaction_List?accountCode=${ParamAccountCode || props.AccountCode}&ExactMatch=true`:`NominalTransaction/MANominalTransaction_List?StartDate=${state.startDate}&EndDate=${state.endDate}`;

            if (!state.startDate || !state.endDate) {
                console.warn('StartDate or EndDate is missing in the state');
                return;
            }
            const params = {
                MAMappingCategoryID: state.mappingCategoryIds || [],
                DivisionCode: state.divisionsIds || [],
                CostCentreGroup: state.departmentCodeIds || [],
                CostCentre: state.costCentreIds || [],
                ExpenseGroup: state.expenseGroupIds || [],
                ExpenseCode: state.expenseCodeIds || []
            };

            const queryString = queryStringFromArray(params);
            const fullUrl = `${baseUrl}&${queryString}`;

            console.log('URL:', fullUrl);

            setGridUrl(fullUrl);
            setIsReload(new Date());
        } catch (error) {
            console.error('Error in useEffect while generating the URL:', error);
        }
    }, [state]);

    useEffect(() => {
        let fetchData = async () => {
            try {
                let res = await getMA_GetNominalTransactionDropdowns();
                if (res && res.success) {
                    setList((st) => ({
                        ...st,
                        costCentreList: res.data?.costCentre,
                        departmentCodeList: res.data?.costCentreGroup,
                        divisionsList: res.data?.divisions,
                        expenseCodeList: res.data?.expenseCode,
                        expenseGroupList: res.data?.expenseGroup,
                        mappingCategoryList: res.data?.mappingCategory
                    }));
                    setState((st) => ({
                        ...st,
                        startDate: moment(res.data?.divisions[0]?.startDate).format('YYYY-MM-DD'),
                        endDate: moment(res.data?.divisions[0]?.endDate).format('YYYY-MM-DD')
                    }));
                }
            } catch (err) {
                console.error('Error While API load:', err);
            }
        };
        return fetchData();
    }, []); 

    const AccCodesColumnWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleHistoryModal(record.accountCode)}
                >
                    {value}
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                {record.invoiceFileName ? (
                    <div
                        style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                        onClick={() => handleOpenModal(record.invoiceFileName)}
                    >
                        {value}
                    </div>
                ) : (
                    <div
                        style={{
                            cursor: ''
                        }}
                    >
                        {value}
                    </div>
                )}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const handleSelect = (name) => (event, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value.map((c) => c.id) };
            return nst;
        });
    };

    const column = useMemo(() => {
        let col = [...columns];

        if (!props.AccountCode) {
            col.splice(0, 0, { field: 'accountCode', title: 'Account Code', width: 130, renderer: AccCodesColumnWrapper });
            col.splice(8, 0, { field: 'division', title: 'Division', width: 150 });
            col.splice(9, 0, { field: 'costCentreName', title: 'Cost Centre', width: 250 });
            col.splice(10, 0, { field: 'expenseName', title: 'Expense Name', width: 250 });
            col.splice(11, 0, { field: 'branch', title: 'Branch Name', width: 100 });
            col.splice(12, 0, { field: 'department', title: 'Department Name', width: 150 });
            col.splice(13, 0, { field: 'model', title: 'Model', width: 150 });
            col.splice(14, 0, { field: 'period', title: 'Period', width: 60 });
            col.splice(15, 0, { field: 'year', title: 'Year', width: 60 });
        }

        let colAction = col.find((m) => m.field === 'documentNumber');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, [props.AccountCode]);

    return (
        <div className="report-screen-container">
            {!props.AccountCode && <BreadCrumbs crumbs={crumbs} />}
            {!props.AccountCode && 
            <Grid container spacing={1} style={{ marginTop: 3 }}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <InputLabel shrink>Division </InputLabel>
                    <Multiselect options={list.divisionsList || []} onChange={handleSelect('divisionsIds')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <InputLabel shrink>Cost Centre Group </InputLabel>
                    <Multiselect options={list.departmentCodeList || []} onChange={handleSelect('departmentCodeIds')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
                    <InputLabel shrink>Expense Group</InputLabel>
                    <Multiselect options={list.expenseGroupList || []} onChange={handleSelect('expenseGroupIds')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <DatePicker name="startDate" fullWidth value={state.startDate} onChange={handleFieldChange} style={{ marginTop: 8 }} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <InputLabel shrink>Mapping Category </InputLabel>
                    <Multiselect options={list.mappingCategoryList || []} onChange={handleSelect('mappingCategoryIds')} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <InputLabel shrink>Cost Centre </InputLabel>
                    <Multiselect options={updatedList.filteredCostCentre || []} onChange={handleSelect('costCentreIds')} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
                    <InputLabel shrink>Expense Category</InputLabel>
                    <Multiselect options={updatedList.filteredExpenseCode || []} onChange={handleSelect('expenseCodeIds')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <InputLabel shrink>End Date</InputLabel>
                    <DatePicker name="endDate" fullWidth value={state.endDate} onChange={handleFieldChange} style={{ marginTop: 8 }} />
                </Grid>
                {/* <Grid item xs={3}>
                    <InputLabel shrink>Expense Group</InputLabel>
                    <Multiselect options={list.expenseGroup||[]} onChange={handleSelect('expenseGroup')} />
                </Grid> */}
            </Grid>
            }
            <Grid item xs={12}>
                <MananageAccountGrid
                    columns={column}
                    getUrl={gridUrl}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={isReload}
                />
            </Grid>
            {modalOpen ? (
                <DialogComp title="Invoice" onClose={() => handleCloseModal()} maxWidth="xl" fullWidth>
                    <NominalTransInvoice invoiceFileName={invoiceFileName} />
                </DialogComp>
            ) : null}
            {historyModalOpen ? (
                <DialogComp title={`History - ${AccountCode}`} onClose={() => handleCloseModal()} maxWidth="xl" fullScreen overflow>
                    <AccountCodesHistory AccountCode={AccountCode} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default NominalTransaction;
