import Tooltip from '@material-ui/core/Tooltip';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Button, CircularProgress, Grid, Badge, IconButton, Avatar, Chip } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';

import './Controls.scss';
import { useState } from 'react';

export const DefaultButton = (props) => {
    const { isBtnLoader, ...rest } = props;

    return (
        <Button
            variant="contained"
            // disabled={isBtnLoader}
            {...rest}
        >
            {!isBtnLoader ? <>{props.children}</> : <CircularProgress size={20} />}
        </Button>
    );
};

export const PrimaryButton = (props) => {
    const { isBtnLoader, ...rest } = props;

    return (
        <DefaultButton {...props} color={isBtnLoader ? 'default' : 'primary'}>
            {props.children}
        </DefaultButton>
    );
};

export const SecondaryButton = (props) => {
    return (
        <DefaultButton {...props} color="secondary">
            {props.children}
        </DefaultButton>
    );
};

export const AddBoxButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn">
            <Tooltip title={toolTipTitle || 'Add'} arrow>
                <AddBoxIcon style={{ fontSize: iconFontSize || 22, marginTop: 10, cursor: 'pointer', ...rest.style }} color="secondary" {...rest} />
            </Tooltip>
        </span>
    );
};

export const EditButton = (props) => {
    const { toolTipTitle, iconFontSize, onClick, ...rest } = props;
    const [show, setShow] = useState(false);
    return (
        <span className="modal-btn-update">
            <Tooltip
                title={toolTipTitle || 'Edit'}
                arrow
                open={show}
                disableHoverListener
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <EditIcon
                    style={{ fontSize: iconFontSize || 22, marginTop: 5 }}
                    onClick={() => {
                        setShow(false);
                        if (onClick) return onClick();
                    }}
                    color="secondary"
                    {...rest}
                />
            </Tooltip>
        </span>
    );
};

export const GreenButton = (props) => {
    return (
        <Button className="greenbtn" fullWidth variant="contained" {...props}>
            {props.children}
        </Button>
    );
};

export const UploadWithBadge = (props) => {
    const { toolTipTitle, count, ...rest } = props;
    return (
        <Badge
            badgeContent={count || 0}
            // showZero
            max={99}
            color="primary"
            anchorOrigin={{
                vertical: 'top',
                horizontal: rest.direction ? rest.direction : 'left'
            }}
        >
            <div className="uploadWithBadgeButton">
                <Tooltip title={toolTipTitle || ''} arrow>
                    {/* <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}> */}
                    <CameraAltIcon className="doneIcon" {...rest} />
                    {/* </DefaultButton> */}
                </Tooltip>
            </div>
        </Badge>
    );
};

export const YesButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                    {rest.btnName || 'Yes'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const NoButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btninActive' : 'btnActiveNo'} value={true} {...rest}>
                    {rest.btnName || 'No'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const SquareButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Grid>
                <IconButton
                    disabled={props.disabled}
                    style={
                        props.style
                            ? props.style
                            : {
                                  backgroundColor: 'green',
                                  borderRadius: '20%',
                                  width: '32px',
                                  height: '32px',
                                  textAlign: 'center',
                                  padding: '0px 0px 0px 0px'
                              }
                    }
                    onClick={props.onClick ? props.onClick : ''}
                    // size="small"
                >
                    {props.children}
                </IconButton>
            </Grid>
        </Tooltip>
    );
};

export const CustomIconButton = (props) => {
    const { toolTipTitle, disabled, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Grid>
                <IconButton
                    style={props.style ? props.style : { padding: '0px' }}
                    onClick={props.onClick ? props.onClick : ''}
                    size="small"
                    disabled={disabled}
                >
                    {props.children}
                </IconButton>
            </Grid>
        </Tooltip>
    );
};

export const YesNoButton = (props) => {
    const { toolTipTitle, onYesClick, onNoClick, ...rest } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
                <Tooltip title={toolTipTitle || ''} arrow>
                    <div>
                        <DefaultButton onClick={onYesClick} className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                            {rest.btnName || 'Yes'}
                        </DefaultButton>
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={6} sm={6}>
                <Tooltip title={toolTipTitle || ''} arrow>
                    <div>
                        <DefaultButton onClick={onNoClick} className={rest.state ? 'btninActive' : 'btnActiveNo'} value={false} {...rest}>
                            {rest.btnName || 'No'}
                        </DefaultButton>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export const ChipWithoutLable = (props) => {
    const { toolTipTitle, onClick, ...rest } = props;
    const [show, setShow] = useState(false);
    const Icon = props.icon;
    const iconFontSize = props.iconFontSize;

    return (
        <Tooltip title={toolTipTitle} arrow open={show} disableHoverListener onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <Chip
                avatar={<Avatar className="chip-without-lable">{Icon ? <Icon style={{ fontSize: iconFontSize || 20 }} /> : ''}</Avatar>}
                onClick={() => {
                    setShow(false);
                    if (onClick) return onClick();
                }}
                {...rest}
            />
        </Tooltip>
    );
};
