import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getInspectionSheetTypebyId, postVehicleInspectionSheetType } from '../../../Core/Service/vehicleChecks';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';

const AddUpdateVehicleSections = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        inspectionSheetTypeDIsplayRoadTest: 'yes',
        inspectionSheetTypeDisplayBrakePerformance: 'yes',
        inspectionSheetTypeDisplayBrakeTest: 'yes',
        inspectionSheetTypeDisplayMileage: 'yes',
        inspectionSheetTypeDisplayTyres: 'yes',
        errors: {}
        // inspectionSheetTypeDisplayStartEndEvent: 'yes'
        // inspectionSheetTypeStartEventName: '',
        // inspectionSheetTypeEndEventName: ''
    });

    useEffect(() => {
        let fetchData = async () => {
            try {
                if (props.InspectionTypeID) {
                    let res = await getInspectionSheetTypebyId(props.InspectionTypeID);
                    let data = res.data.list[0];
                    if (res.success) {
                        setState((st) => ({
                            ...st,
                            inspectionSheetTypeID: data.inspectionSheetTypeID,
                            inspectionSheetTypeName: data.inspectionSheetTypeName,
                            inspectionSheetTypeDisplayTyres: data.inspectionSheetTypeDisplayTyres ? 'yes' : 'no',
                            inspectionSheetTypeDisplayBrakeTest: data.inspectionSheetTypeDisplayBrakeTest ? 'yes' : 'no',
                            inspectionSheetTypeDisplayBrakePerformance: data.inspectionSheetTypeDisplayBrakePerformance ? 'yes' : 'no',
                            inspectionSheetTypeDIsplayRoadTest: data.inspectionSheetTypeDIsplayRoadTest ? 'yes' : 'no',
                            inspectionSheetTypeDisplayMileage: data.inspectionSheetTypeDisplayMileage ? 'yes' : 'no'
                            // inspectionSheetTypeDisplayStartEndEvent: data.inspectionSheetTypeDisplayStartEndEvent ? 'yes' : 'no'
                            // inspectionSheetTypeStartEventName: data.inspectionSheetTypeStartEventName,
                            // inspectionSheetTypeEndEventName: data.inspectionSheetTypeEndEventName
                        }));
                    }
                }
            } catch (error) {}
        };

        return fetchData();
    }, [props.InspectionTypeID]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // if (name == 'inspectionSheetTypeDisplayStartEndEvent' && value == 'no') {
        //     setState((state) => ({ ...state, [name]: value, inspectionSheetTypeStartEventName: '', inspectionSheetTypeEndEventName: '' }));
        // } else {
        //     setState((state) => ({ ...state, [name]: value }));
        // }
        setState((state) => ({ ...state, [name]: value }));
    };

    const ModuleChange = (e, value) => {
        setState((st) => {
            const nst = { ...st, checkVehicleCategoryIDs: value.map((c) => c.id), categoryID: value };
            return nst;
        });
    };

    const submitHandler = async () => {
        let formData = {
            inspectionSheetTypeID: state.inspectionSheetTypeID,
            inspectionSheetTypeName: state.inspectionSheetTypeName,
            inspectionSheetTypeDisplayTyres: state.inspectionSheetTypeDisplayTyres === 'yes' ? true : false,
            inspectionSheetTypeDisplayBrakeTest: state.inspectionSheetTypeDisplayBrakeTest === 'yes' ? true : false,
            inspectionSheetTypeDisplayBrakePerformance: state.inspectionSheetTypeDisplayBrakePerformance === 'yes' ? true : false,
            inspectionSheetTypeDIsplayRoadTest: state.inspectionSheetTypeDIsplayRoadTest === 'yes' ? true : false,
            inspectionSheetTypeDisplayMileage: state.inspectionSheetTypeDisplayMileage === 'yes' ? true : false
            // inspectionSheetTypeDisplayStartEndEvent: state.inspectionSheetTypeDisplayStartEndEvent === 'yes' ? true : false,
            // inspectionSheetTypeStartEventName: state.inspectionSheetTypeStartEventName,
            // inspectionSheetTypeEndEventName: state.inspectionSheetTypeEndEventName
        };

        let res = await postVehicleInspectionSheetType(formData);
        if (res.success) {
            showToast('Vehicle Inspection Sheet Added Successfully');
            props.onClose(true);
        } else {
            showToast(res.message);
            props.onClose(false);
        }
    };

    console.log(state, 'statess');

    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                {/* <MandatoryField
                    inputLabel="Name"
                    placeholder="Name"
                    name="inspectionSheetTypeName"
                    value={state.inspectionSheetTypeName}
                    onChange={handleInputChange}
                /> */}
                <InputLabel shrink required>
                    Name
                </InputLabel>
                <TextBox name="inspectionSheetTypeName" value={state.inspectionSheetTypeName} onChange={handleInputChange} />
                <FormHelperText error>{state.errors.inspectionSheetTypeName}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <InputLabel shrink className="input_label">
                            Display Tyres
                        </InputLabel>
                    </Grid>
                    <Grid xs={12}>
                        <RadioGroup row name="inspectionSheetTypeDisplayTyres" value={state.inspectionSheetTypeDisplayTyres} onChange={handleInputChange}>
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <InputLabel shrink className="input_label">
                            Display Road Test
                        </InputLabel>
                    </Grid>
                    <Grid xs={12}>
                        <RadioGroup row name="inspectionSheetTypeDIsplayRoadTest" value={state.inspectionSheetTypeDIsplayRoadTest} onChange={handleInputChange}>
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <InputLabel shrink className="input_label">
                            Display Brake Test
                        </InputLabel>
                    </Grid>
                    <Grid xs={12}>
                        <RadioGroup
                            row
                            name="inspectionSheetTypeDisplayBrakeTest"
                            value={state.inspectionSheetTypeDisplayBrakeTest}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <InputLabel shrink className="input_label">
                            Display Brake Performance
                        </InputLabel>
                    </Grid>
                    <Grid xs={12}>
                        <RadioGroup
                            row
                            name="inspectionSheetTypeDisplayBrakePerformance"
                            value={state.inspectionSheetTypeDisplayBrakePerformance}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <InputLabel shrink className="input_label">
                            Display Mileage
                        </InputLabel>
                    </Grid>
                    <Grid xs={12}>
                        <RadioGroup row name="inspectionSheetTypeDisplayMileage" value={state.inspectionSheetTypeDisplayMileage} onChange={handleInputChange}>
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
            {/* <Grid item xs={6}>
                <InputLabel shrink className="input_label">
                    Use Start and End Events
                </InputLabel>
                <RadioGroup
                    row
                    name="inspectionSheetTypeDisplayStartEndEvent"
                    value={state.inspectionSheetTypeDisplayStartEndEvent}
                    onChange={handleInputChange}
                >
                    <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                    <FormControlLabel value={'no'} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
            {state.inspectionSheetTypeDisplayStartEndEvent == 'yes' ? (
                <>
                    <Grid item xs={6}>
                        <InputLabel shrink className="input_label">
                            Start Event Name
                        </InputLabel>
                        <SimpleField
                            placeholder="Start Event Name"
                            name="inspectionSheetTypeStartEventName"
                            value={state.inspectionSheetTypeStartEventName}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink className="input_label">
                            End Event Name
                        </InputLabel>
                        <SimpleField
                            placeholder="End Event Name"
                            name="inspectionSheetTypeEndEventName"
                            value={state.inspectionSheetTypeEndEventName}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </>
            ) : null} */}
            {/* <Grid item xs={6} className="submit_btn"></Grid> */}

            <Grid item xs={6} className="submit_btn">
                <SecondaryButton fullWidth onClick={submitHandler}>
                    {props.InspectionTypeID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton fullWidth onClick={() => props.onClose()}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddUpdateVehicleSections;
