import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core';

// import '../../../commonStyle.scss';

import { getAllVehicleCheckSectionDropDown, getVehicleCheckSectionById, postVehicleCheckSectionData } from '../../../../Core/Service/vehicleChecks';
import AppContext from '../../../../App/AppContext';
import { Multiselect, SecondaryButton, SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';

const CheckSectionModal = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        inspectionSheetSectionID: null,
        inspectionSheetSectionDescription: '',
        inspectionSheetSectionPosition: '',
        inspectionSheetSectionActive: true,
        vehicleCategoryDescription: '',
        categoryID: [],
        errors: {},
        categoryList: [],
        sectionVehicleCategoryIDs: [],
        inspectionSheetTypeName: '',
        inspectionSheetSectionTypeID: props.tabID
    });
    const [category, setCategory] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.checkSectionID) {
                    let res = await getVehicleCheckSectionById(props.checkSectionID);
                    if (res.success && res.data.details.length > 0) {
                        let data = res.data.details[0];
                        setState((state) => ({
                            ...state,
                            inspectionSheetSectionID: data.inspectionSheetSectionID,
                            inspectionSheetSectionDescription: data.inspectionSheetSectionDescription,
                            inspectionSheetSectionPosition: data.inspectionSheetSectionPosition,
                            vehicleCategoryDescription: data.inspectionSheetSectionVehicleCategoryID,
                            inspectionSheetSectionActive: data.inspectionSheetSectionActive,
                            sectionVehicleCategoryIDs: res.data.sectionVehicleCategoryIDs
                        }));
                    }
                }

                let res2 = await getAllVehicleCheckSectionDropDown();
                setState((state) => ({
                    ...state,
                    categoryList: res2.data.list.map((item) => ({
                        id: item.vehicleCategoryID,
                        name: item.vehicleCategoryDescription
                    }))
                }));
            } catch (error) {
                console.warn(error);
            }
        };

        fetchData();
    }, [props.checkSectionID]);

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            let AllData = [...state.categoryList];
            setState((st) => ({ ...st, categoryID: AllData, sectionVehicleCategoryIDs: AllData.map((k) => k.id) }));
        }
        if (type === 'clear') {
            setState((st) => ({ ...st, categoryID: [], sectionVehicleCategoryIDs: [] }));
        }
    };

    useEffect(() => {
        function fetchData() {
            try {
                let NewArray = [];
                if (state.sectionVehicleCategoryIDs.length > 0) {
                    const NewArray = state.categoryList.filter((w) => state.sectionVehicleCategoryIDs.indexOf(w.id) > -1);

                    setState((st) => ({ ...st, categoryID: NewArray }));
                }
            } catch (error) {
                console.warn(error);
            }
        }
        return fetchData();
    }, [state.sectionVehicleCategoryIDs, state.categoryList]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { inspectionSheetSectionDescription, inspectionSheetSectionPosition, vehicleCategoryDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(inspectionSheetSectionDescription)) {
            errors.inspectionSheetSectionDescription = 'This field is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(inspectionSheetSectionPosition)) {
            errors.inspectionSheetSectionPosition = 'This field is required';
            formIsValid = false;
        }
        // if (!emptyStrigPattern.test(vehicleCategoryDescription)) {
        //     errors.vehicleCategoryDescription = 'This field is required';
        //     formIsValid = false;
        // }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    // public short InspectionSheetSectionTypeID { get; set; }
    //     public short? InspectionSheetSectionPosition { get; set; }
    //     public string InspectionSheetSectionDescription { get; set; }
    //     public bool InspectionSheetSectionActive { get; set; }
    //     public string InspectionSheetTypeName { get; set; }
    //     public List<short> SectionVehicleCategoryIDs { get; set; }

    const submitHandler = async () => {
        if (validations()) {
            const checkSectionData = {
                inspectionSheetSectionID: state.inspectionSheetSectionID,
                inspectionSheetSectionPosition: state.inspectionSheetSectionPosition,
                inspectionSheetSectionDescription: state.inspectionSheetSectionDescription,
                inspectionSheetSectionActive: state.inspectionSheetSectionActive,
                // vehicleCategoryDescription: state.vehicleCategoryDescription,
                // inspectionSheetSectionVehicleCategoryID: state.vehicleCategoryDescription,
                sectionVehicleCategoryIDs: state.sectionVehicleCategoryIDs,
                inspectionSheetTypeName: state.inspectionSheetTypeName,
                inspectionSheetSectionTypeID: state.inspectionSheetSectionTypeID
            };
            let res = await postVehicleCheckSectionData(checkSectionData);
            if (res.success) {
                showToast('Vehicle Check section added successfully');
                props.onClose(true);
            } else {
                showToast(res.message);
                // props.onClose(false);
            }
        }
    };

    const ModuleChange = (e, value) => {
        setState((st) => {
            const nst = { ...st, sectionVehicleCategoryIDs: value.map((c) => c.id), categoryID: value };
            return nst;
        });
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={10} className="mandatory-fields">
                {/* <MandatoryField
                    inputLabel="Section Name"
                    name="inspectionSheetSectionDescription"
                    onChange={handleInputChange}
                    value={state.inspectionSheetSectionDescription}
                    errors={state.errors.inspectionSheetSectionDescription}
                /> */}

                <InputLabel shrink required>
                    Sequence
                </InputLabel>
                <TextBox name="inspectionSheetSectionDescription" value={state.inspectionSheetSectionDescription} onChange={handleInputChange} />
                <FormHelperText error>{state.errors.inspectionSheetSectionDescription}</FormHelperText>
            </Grid>
            <Grid item xs={2} className="mandatory-fields">
                {/* <MandatoryField
                    inputLabel="Position"
                    name="inspectionSheetSectionPosition"
                    onChange={handleInputChange}
                    value={state.inspectionSheetSectionPosition}
                    errors={state.errors.inspectionSheetSectionPosition}
                /> */}
                <InputLabel shrink required>
                    Position
                </InputLabel>
                <TextBox name="inspectionSheetSectionPosition" value={state.inspectionSheetSectionPosition} onChange={handleInputChange} />
                <FormHelperText error>{state.errors.inspectionSheetSectionPosition}</FormHelperText>
            </Grid>
            {/* <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    IM Reference
                </InputLabel>
                <SelectBox
                    name="vehicleCategoryDescription"
                    value={state.vehicleCategoryDescription || ' '}
                    onChange={handleInputChange}
                    List={state.docsCategoryList}
                />
                <FormHelperText error>{state.errors.vehicleCategoryDescription}</FormHelperText>
            </Grid> */}
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Vehicle Categories
                </InputLabel>

                <Multiselect
                    options={state.categoryList}
                    value={state.categoryID}
                    onChange={ModuleChange}
                    style={{ marginTop: -8 }}
                    selectAll={randerSelectAll}
                />
                {/* <SelectBox
                    name="vehicleCategoryDescription"
                    value={state.vehicleCategoryDescription || ' '}
                    onChange={handleInputChange}
                    List={state.categoryList}
                /> */}

                <FormHelperText error>{state.errors.vehicleCategoryDescription}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                {/* <SecondaryCheckbox
                    checked={state.inspectionSheetSectionActive}
                    name="inspectionSheetSectionActive"
                    onChange={handleCheckbox}
                    label="Is Active ?"
                /> */}
                <FormControlLabel
                    control={<SecondaryCheckbox checked={state.inspectionSheetSectionActive} onChange={handleCheckbox} name="inspectionSheetSectionActive" />}
                    label="Is Active ?"
                />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton fullWidth onClick={submitHandler}>
                    {props.checkSectionID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default CheckSectionModal;
