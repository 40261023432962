import React, { useEffect, useState } from 'react';
// import DialogComp from '../../../../Core/Dialog/DialogComp';
// import { BasicTable, VehicleCheckTable } from './tableCheckcheck';
import { Button, Grid, IconButton, Select, withStyles } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import './vehicleStyle.scss';
import EditIcon from '@material-ui/icons/Edit';
import { Label } from 'recharts';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddUpdateVehicleSections from './addUpdatevehicleSection';
import { set } from 'lodash';
import { getAllvehiclecheckchecks, getVehicleCheckSection, getVehicleDocsCategory } from '../../../Core/Service/vehicleChecks';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import TabComponent from '../../../Core/Controls/Tabs';
import { SecondaryButton } from '../../../Core/FormInput';
import { VehicleCheckTable } from './tableCheckcheck';
import DialogComp from '../../../Core/Modal/dialogModal';
import CheckSectionModal from './CheckSection/checkSectionModal';
import SimpleAccordion from '../../../Core/Controls/Accordion';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicle Checks', active: true },
    { name: 'Inspection Checks', active: true }
];

const VehicleCheckCheck = (props) => {
    const [state, setState] = useState({
        checkList: [],
        VehicleCategoryList: [],
        accOpen: false,
        tabs: [],
        accHeading: [],
        modalToggle: false,
        expandCollapsedAt: null,
        tabsToggle: false,
        inspectionTypeID: null,
        checkCheckList: []
    });

    useEffect(() => {
        pullcheckData();
        pullData();
    }, []);

    const pullcheckData = async () => {
        let pros = [];
        pros.push(getVehicleDocsCategory(), getVehicleCheckSection());
        let responses = await Promise.all(pros);
        // accordion data.....
        const accHeading = responses[1].data.map((m) => ({
            id: m.inspectionSheetSectionTypeID,
            heading: m.inspectionSheetSectionDescription,
            checkID: m.inspectionSheetSectionID,
            sectionVehicleCategoryIDs: m.sectionVehicleCategoryIDs
        }));

        // Tabs data....
        const tabs = responses[0].data.list.map((item) => ({
            id: item.id,
            label: item.name,
            accData: accHeading.filter((data) => data.id === item.id)
        }));

        const AddBtn = {
            id: null,
            label: <AddBoxIcon color="secondary" onClick={handleSection} style={{ cursor: 'pointer' }} />,
            accData: []
        };

        tabs.push(AddBtn);

        setState((st) => ({
            ...st,
            accHeading,
            tabs
        }));
    };

    const pullData = async () => {
        try {
            let res = await getAllvehiclecheckchecks();
            setState((st) => ({
                ...st,
                checkCheckList: res.data.list || []
            }));
        } catch (error) {
            console.warn(error);
        }
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false, tabsToggle: false }));
        res && pullcheckData();
        res && pullData();
    };

    const handleExpand = (value) => {
        setState((state) => ({
            ...state,
            expandCollapsedAt: new Date(),
            accOpen: value
        }));
    };
    const addCheckSections = (tabID, id) => {
        setState((st) => ({
            ...st,

            modalToggle: true,
            sectionID: id,
            tabID: tabID
        }));
        handleExpand(false);
    };

    const handleSection = (val) => {
        setState((st) => ({ ...st, tabsToggle: true, inspectionTypeID: val?.id }));
    };

    const title = `${!state.sectionID ? 'Add' : 'Update'} Check Section`;

    const checkChecks = state.tabs.map((tab, index) => {
        const heading = tab.accData.map((o) => o.heading);
        const filterAccordion = tab.accData.filter((o, index) => !heading.includes(o.heading, index + 1));
        return {
            label: (
                <div>
                    {tab.id ? (
                        <span style={{ display: 'flex', justifyContent: 'space-around', padding: 0, alignItems: 'center' }}>
                            <IconButton onClick={() => handleSection(tab)}>
                                <EditIcon style={{ color: '#f50057', fontSize: 15 }} size="small" />
                            </IconButton>
                            {tab.label}
                        </span>
                    ) : (
                        tab.label
                    )}
                </div>
            ),
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item style={{ marginTop: 10 }}>
                                <SecondaryButton
                                    variant="contained"
                                    style={{ width: 'auto', padding: '4px 10px', fontWeight: 800, textTransform: 'capitalize' }}
                                    color="primary"
                                    onClick={() => addCheckSections(tab.id)}
                                >
                                    + Add Section
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {filterAccordion.map((ad) => (
                            <SimpleAccordion
                                header={
                                    <div style={{ fontWeight: 700, color: '#f50057' }}>
                                        <IconButton onClick={() => addCheckSections(tab.id, ad.checkID)}>
                                            <EditIcon style={{ color: '#f50057', fontSize: 20 }} size="small" />
                                        </IconButton>
                                        {ad.heading}{' '}
                                    </div>
                                }
                                changedAt={state.expandCollapsedAt}
                                Expand={state.accOpen}
                            >
                                <VehicleCheckTable
                                    checksMainID={ad.id}
                                    checksID={ad.checkID}
                                    accName={ad.heading}
                                    List={state.checkCheckList}
                                    pullData={pullData}
                                    sectionVehicleCategoryIDs={ad.sectionVehicleCategoryIDs}
                                />
                            </SimpleAccordion>
                        ))}
                    </Grid>
                </Grid>
            )
        };
    });

    return (
        <div className="inspectionScreen">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs>
                            <BreadCrumbs crumbs={crumbs} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleExpand(true);
                                }}
                                size="small"
                                color="secondary"
                                style={{ textTransform: 'capitalize' }}
                            >
                                Expand All
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleExpand(false);
                                }}
                                size="small"
                                color="secondary"
                                style={{ marginLeft: 2, textTransform: 'capitalize' }}
                            >
                                Collapse All
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <TabComponent tabList={checkChecks} id="check_check" selectedIndex={0} />
                </Grid>

                {state.modalToggle ? (
                    <DialogComp title={title} onClose={() => closeDialog(false)}>
                        <CheckSectionModal onClose={() => closeDialog(true)} checkSectionID={state.sectionID} tabID={state.tabID} />
                    </DialogComp>
                ) : null}

                {state.tabsToggle ? (
                    <DialogComp title={state.inspectionTypeID ? 'Update Inspection Type' : 'Add Inspection Type'} onClose={() => closeDialog(false)}>
                        <AddUpdateVehicleSections InspectionTypeID={state.inspectionTypeID} onClose={() => closeDialog(true)} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default VehicleCheckCheck;
