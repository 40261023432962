import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import ReactDOM from 'react-dom';

import ManageAccountGrid from '../../../managementAccount/manageAccountGrid';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import MappingSubModal from '../NominalMapping/mappingSubModal';
import NominalCategoryAddEditModal from './categoryEditModal';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Nominal Account Codes', active: true }
];

const basecolumns = [
    { field: 'accountCode', title: 'Account Code', width: 150 },
    { field: 'branch', title: 'Branch', width: 90 },
    { field: 'branchName', title: 'Branch Name' },
    { field: 'department', title: 'Department', width: 100 },
    { field: 'departmentName', title: 'Department Name' },
    { field: 'model', title: 'Model', width: 80 },
    { field: 'modelName', title: 'Model Name' },
    { field: 'expenseCategory', title: 'Expense Category', width: 130 },
    { field: 'expenseCategoryName', title: 'Expense Category Name' },
    { field: 'maMappingCategoryID', title: 'Category', width: 100 },
    { field: 'maMappingCategoryName', title: 'Category Name' }
];

// Centralized mapping for position IDs
const positionMapping = {
    1: { key: 'branch', description: 'Branch' },
    2: { key: 'department', description: 'Department' },
    4: { key: 'model', description: 'Model' },
    5: { key: 'expenseCategory', description: 'Expense Category' }
};

function NominalAccountCodes(props) {
    const [state, setState] = useState({
        open: false,
        positionID: '',
        mappingValue: '',
        IsEmptyBranch: false,
        IsEmptyDepartment: false,
        IsEmptyModel: false,
        IsEmptyExpenseCategory: false,
        IsEmptyCategory: false
    });

    // Refactored addBtnClicked function
    const addBtnClicked = (positionID, record) => {
        const mapping = positionMapping[positionID];
        if (!mapping) return;

        const mappingValue = record[mapping.key];
        const mappingValueDescription = mapping.description;

        setState((st) => ({
            ...st,
            showMappingModal: true,
            selectedRowData: record,
            positionID: positionID,
            mappingValue,
            mappingValueDescription
        }));
    };

    // Refactored editButton function
    const editButton = (positionID) => (value, record) => {
        const mapping = positionMapping[positionID];
        if (!mapping) return value;

        const spn = document.createElement('span');
        const lnk = (
            <Grid container>
                {record[mapping.key] && !value ? <LinkIcon onClick={() => addBtnClicked(positionID, record)} style={{ cursor: 'pointer' }} /> : value}
            </Grid>
        );

        ReactDOM.render(lnk, spn);
        return spn;
    };

    // Edit category button function
    const editCategoryButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container>
                {
                    // record.maMappingCategoryID &&
                    !value ? <LinkIcon onClick={() => addBtnCategoryClicked(record)} style={{ cursor: 'pointer' }} /> : value
                }
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    // Columns with dynamic renderers
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];

        Object.keys(positionMapping).forEach((positionID) => {
            const { key } = positionMapping[positionID];
            const column = tempCols.find((col) => col.field === `${key}Name`);
            if (column) {
                column.renderer = editButton(Number(positionID));
            }
        });

        let CategoryCol = tempCols.find((e) => e.field === 'maMappingCategoryName');
        if (CategoryCol) {
            CategoryCol.renderer = editCategoryButton;
        }
        return tempCols;
    }, []);

    const addBtnCategoryClicked = (record) => {
        setState((st) => ({
            ...st,
            showCategoryModal: true,
            selectedRowData: record
        }));
    };

    const closeDialog = (isReload) => {
        let newSt = {};
        newSt.showMappingModal = false;
        newSt.showCategoryModal = false;

        if (isReload) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    return (
        <div className="screen">
            <Grid container alignItems="center">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox checked={state.IsEmptyBranch} onChange={handleCheckbox} name="IsEmptyBranch" />} label="Branch" />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={state.IsEmptyDepartment} onChange={handleCheckbox} name="IsEmptyDepartment" />}
                        label="Department"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox checked={state.IsEmptyModel} onChange={handleCheckbox} name="IsEmptyModel" />} label="Model" />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={state.IsEmptyExpenseCategory} onChange={handleCheckbox} name="IsEmptyExpenseCategory" />}
                        label="Expense Category"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={state.IsEmptyCategory} onChange={handleCheckbox} name="IsEmptyCategory" />}
                        label="Category"
                    />
                </Grid>
            </Grid>
            <ManageAccountGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={`NominalCodes/GetNominalAccountCodes?IsEmptyBranch=${state.IsEmptyBranch}&IsEmptyDepartment=${state.IsEmptyDepartment}&IsEmptyModel=${state.IsEmptyModel}&IsEmptyExpenseCategory=${state.IsEmptyExpenseCategory}&IsEmptyCategory=${state.IsEmptyCategory}`}
                isReload={state.isReload}
            />
            {state.showMappingModal ? (
                <DialogComp
                    title={state.selectedRowData?.mappingValueID ? 'Update Mapping Data' : 'Add Mapping Data'}
                    onClose={() => closeDialog(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <MappingSubModal
                        onClose={closeDialog}
                        selectedRowData={state.selectedRowData}
                        maMappingPositionsID={state.positionID}
                        positionLength={'disabled'}
                        mappingValue={state.mappingValue}
                        mappingValueDescription={state.mappingValueDescription}
                    />
                </DialogComp>
            ) : null}
            {state.showCategoryModal ? (
                <DialogComp
                    title={state.selectedRowData?.mappingValueID ? 'Mapping Data' : 'Mapping Data'}
                    onClose={() => closeDialog(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <NominalCategoryAddEditModal onClose={closeDialog} selectedRowData={state.selectedRowData} mappingValue={state.mappingValue} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default NominalAccountCodes;
