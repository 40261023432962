import React, { useContext } from 'react';
import { BranchServiceProvider as StateProvider, BranchServiceContext } from './branchServicesProvider';
import { Grid, Typography } from '@material-ui/core';
import { SelectBox } from '../../../../Core/FormInput';
import './branchService.scss';
import ServiceTable from './branchServiceTabel';
import ServiceTableYTD from './branchServiceYTD';

const BranchServiceUserInterface = (props) => {
    const { state } = useContext(BranchServiceContext);

    return (
        <div className="service_Section">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectBox List={[]} inputLabel={'Branch'} />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectBox List={[]} inputLabel={'Franchise Selection'} />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectBox List={[]} inputLabel={'Period'} />
                        </Grid>
                        <Grid item xs={1}>
                            <SelectBox List={[]} inputLabel={'Year'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className="service-header">
                                Service Department
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ServiceTable />
                        </Grid>
                        <Grid item xs={6}>
                            <ServiceTableYTD />
                        </Grid>
                        {/* <Grid item xs={4}></Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
const BranchService = (props) => {
    return (
        <StateProvider {...props}>
            <BranchServiceUserInterface {...props} />
        </StateProvider>
    );
};
export default BranchService;
