import { FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core';
import './loanStyle.scss';
import { TextBox } from '../../../Core/FormInput';
import { Email } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { SignPad } from '../../../Core/SignaturePad/SignPad';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { useContext } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';

function Note() {
    const { handOverDetails, fieldChange, clearSig, state, handleHandOverDetails, errors } = useContext(LoanVehicleContext);
    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <Alert severity="info" icon={false} style={{ fontSize: '11px', textTransform: 'uppercase', marginBottom: '10px' }}>
                    Please Note: Fuel Must Be Returned At The Level Receive. Fuel Charges Will Apply For Any Fuel Used If Not Refilled
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="legend">Handover by</FormLabel>
                <TextBox name="handedOverName" value={handOverDetails.handedOverName} onChange={handleHandOverDetails} disabled={state.isVehicleReceived} />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="warning" icon={false} style={{ fontSize: '13px', marginBottom: '10px' }}>
                    * By signing this form you acknowledge that you have read and agreed to the terms and conditions outlined overleaf.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                {state.handOverCustomerSignature ? (
                    <>
                        <FormLabel component="legend" required error>
                            Customer signature
                        </FormLabel>
                        <br />
                        <div style={{ width: '100%', height: '177px', border: '1px solid #f5f5f5' }}>
                            <img src={state.handOverCustomerSignature} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                    </>
                ) : (
                    <CustomeSignPad
                        content="Customer signature"
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.handOverCustomerSignature}
                        name="handOverCustomerSignature"
                        isRequired
                        disabled={state.isVehicleReceived}
                    />
                )}
                {errors.handOverCustomerSignature && <FormHelperText error>{errors.handOverCustomerSignature}</FormHelperText>}
            </Grid>
        </Grid>
    );
}

export default Note;
