import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableHead, TableContainer, TableBody, TableCell, Button, withStyles, IconButton } from '@material-ui/core';

import VehicleCheckCheckMdal from './vehicleCheckCheckModal';
import AddIcon from '@material-ui/icons/Add';
import './vehicleStyle.scss';
import { lightGreen } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { AddBoxButton, EditButton } from '../../../Core/FormInput/AppButton';
import DialogComp from '../../../Core/Modal/dialogModal';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#FFD6D7',
        // color: theme.palette.common.white,
        padding: 1,
        fontSize: 12,
        fontWeight: 700
    },
    body: {
        padding: 3,
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f5f5f5'
        }
    }
}))(TableRow);

export const VehicleCheckTable = (props) => {
    const [state, setState] = useState({
        checkCheckList: [],
        modalToggle: false,
        userID: null,
        addID: null
    });

    useEffect(() => {
        try {
            if (props.List.length > 0) {
                setState((st) => ({ ...st, checkCheckList: props.List.filter((data) => data.inspectionSheetCheckSectionID === props.checksID) }));
            }
        } catch (error) {
            console.warn(error);
        }
    }, [props.List, props.checksID]);

    const handleAddEditModal = (id, addID, accHeading) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            userID: id,
            addID: addID
        }));
    };

    const closeDialog = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        res && props.pullData();
    };

    const title = state.userID ? `Update Vehicle Check in ${props.accName}` : `Add Vehicle Check in ${props.accName}`;
    return (
        <div className="table_component">
            {state.checkCheckList?.length > 0 ? (
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow hover={true} selected={true}>
                                <StyledTableCell align="center" width="10%">
                                    <span className="check-btn">
                                        <AddBoxButton onClick={() => handleAddEditModal(null, props.checksID)}>
                                            {/* <AddIcon fontSize="small" /> */}
                                        </AddBoxButton>
                                    </span>
                                </StyledTableCell>
                                <StyledTableCell align="left" width="25%">
                                    Title
                                </StyledTableCell>
                                <StyledTableCell align="left">IM Reference</StyledTableCell>
                                <StyledTableCell align="left">Position</StyledTableCell>
                                <StyledTableCell align="left">Legally Required</StyledTableCell>
                                <StyledTableCell align="left">Is Active ?</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.checkCheckList.map((m) => (
                                <StyledTableRow key={m.inspectionSheetCheckID}>
                                    <StyledTableCell component="th" scope="row" align="center">
                                        <EditButton onClick={() => handleAddEditModal(m.inspectionSheetCheckID)} />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{m.inspectionSheetCheckDescription}</StyledTableCell>
                                    <StyledTableCell align="left">{m.inspectionSheetIMReference}</StyledTableCell>
                                    <StyledTableCell align="left">{m.inspectionSheetCheckPosition}</StyledTableCell>
                                    <StyledTableCell align="left">{m.inspectionSheetCheckLegalRequirement ? 'Yes' : 'No'} </StyledTableCell>
                                    <StyledTableCell align="left">{m.inspectionSheetCheckActive ? 'Yes' : 'No'}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Alert icon={false} color="info">
                    No record found. Please click{' '}
                    <IconButton onClick={() => handleAddEditModal(null, props.checksID)} style={{ padding: '0px 4px', color: '#f50057' }}>
                        <AddBoxIcon />
                    </IconButton>{' '}
                    to create a new record.
                </Alert>
            )}
            {state.modalToggle ? (
                <DialogComp title={title} onClose={closeDialog}>
                    <VehicleCheckCheckMdal
                        addID={state.addID}
                        checkUserID={state.userID}
                        onClose={closeDialog}
                        sectionVehicleCategoryIDs={props.sectionVehicleCategoryIDs}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};
