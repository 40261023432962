import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DialogContents, SecondaryButton } from '../../../Core/FormInput';
import { TextBox } from '../../../Core/FormInput';
import { Grid, FormLabel, Typography } from '@material-ui/core';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { getCabDeclarations, saveCabDeclarations } from '../../../Core/Service/cabDeclaration.js';
import parse from 'html-react-parser';
import { Alert } from '@material-ui/lab';

const CabDeclaration = (props) => {
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);
    let HeaderRecordID = props?.match?.params?.headerRecordID;
    const [state, setState] = useState({
        cabDeclarationPrintName: '',
        cabDeclarationSignature: null,
        errors: {},
        IssuerSignature: null,
        cabDeclarationDeclaration: portalSettings.portalSettingCabDeclaration,
        idDisabled: false,
        cabDeclarationDateSigned: null
    });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const hideBracket = state.cabDeclarationCreatedBy || state.cabDeclarationDateSigned;

    useEffect(() => {
        let fetchData = async () => {
            if (HeaderRecordID) {
                let res = await getCabDeclarations(HeaderRecordID);
                if (res.success) {
                    const data = res.data?.details;
                    if (data && data.length > 0) {
                        setState((st) => ({
                            ...st,
                            cabDeclarationPrintName: data[0]?.cabDeclarationPrintName,
                            cabDeclarationSignature: data[0]?.cabDeclarationSignature,
                            IssuerSignature: data[0]?.cabDeclarationSignature ? data[0]?.cabDeclarationSignature : false,
                            cabDeclarationCreatedBy: data[0]?.cabDeclarationCreatedBy,
                            cabDeclarationDateSigned: data[0]?.cabDeclarationDateSigned,
                            // jobId: data[0]?.headerRecordID,
                            idDisabled: true,
                            cabDeclarationDeclaration: data[0]?.cabDeclarationDeclaration || portalSettings.portalSettingCabDeclaration
                        }));
                    } else {
                        setState((st) => ({
                            ...st,
                            cabDeclarationDeclaration: portalSettings.portalSettingCabDeclaration
                        }));
                    }
                }
            }
        };
        return fetchData();
    }, [HeaderRecordID, portalSettings]);

    const validations = () => {
        const { cabDeclarationPrintName, cabDeclarationSignature } = state;
        let formIsValid = true;
        let errors = {};
        if (!cabDeclarationPrintName) {
            errors.cabDeclarationPrintName = 'Print name is required';
            formIsValid = false;
        }
        if (!cabDeclarationSignature) {
            errors.cabDeclarationSignature = 'Signature is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const addCabDeclaration = async () => {
        const data = {
            cabDeclarationPrintName: state.cabDeclarationPrintName,
            cabDeclarationSignature: state.cabDeclarationSignature,
            cabDeclarationDeclaration: state.cabDeclarationDeclaration,
            headerRecordID: HeaderRecordID
        };

        if (validations()) {
            let res = await saveCabDeclarations(data);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const fieldChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            cabDeclarationSignature: null
        }));
    };
    return (
        <DialogContents>
            <div style={{ maxWidth: '60%', margin: '10px auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Alert severity="info" style={{ width: 'auto', height: 'auto' }}>
                            <Typography align="left" style={{ marginTop: -20 }}>
                                {portalSettings.portalSettingCabDeclaration && parse(portalSettings.portalSettingCabDeclaration)}
                            </Typography>
                        </Alert>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <CustomeSignPad
                            onChange={fieldChange}
                            content={
                                <>Signature {hideBracket && <span> ( {`${state.cabDeclarationCreatedBy || ''} ${state.cabDeclarationDateSigned}`} )</span>}</>
                            }
                            sign={state.IssuerSignature}
                            clearSig={clearSig}
                            name="cabDeclarationSignature"
                            hideBtn={false}
                        />
                        <span className="mandatoryfields">{state.errors['cabDeclarationSignature']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Print Name
                        </FormLabel>
                        <TextBox
                            disabled={state.idDisabled}
                            name="cabDeclarationPrintName"
                            value={state.cabDeclarationPrintName}
                            autoComplete="new-password"
                            onChange={inputChange}
                        />
                        <span className="mandatoryfields">{state.errors['cabDeclarationPrintName']}</span>
                    </Grid>
                    <SecondaryButton disabled={state.idDisabled} fullWidth className="mt-20" onClick={addCabDeclaration}>
                        Agree
                    </SecondaryButton>
                </Grid>
            </div>
        </DialogContents>
    );
};

export default CabDeclaration;
