import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, InputLabel, FormHelperText } from '@material-ui/core';
import { MandatoryField } from '../../../../Core/FormInput/inputfields';
import { SecondaryButton, SingelSelect } from '../../../../Core/FormInput';
import AppContext from '../../../../App/AppContext';
import { getMAMappingCategory_Search, postMANominalCategory_AddUpdate } from '../../../../Core/Service/MappingService';

function NominalCategoryAddEditModal({ selectedRowData, onClose }) {
    const [state, setState] = useState({
        errors: {},
        mAMappingCategoryName: selectedRowData.maMappingCategoryName || '',
        mAMappingCategoryID: selectedRowData.maMappingCategoryID || '',
        isReadOnly: false
    });

    const [dropdowns, setDropdowns] = useState({
        categoryList: []
    });

    const [tempOptions, setTempOptions] = useState({
        categoryIDValue: null
    });

    const [btnLoader, setBtnLoader] = useState(false);
    const { showToast } = useContext(AppContext);

    // Fetch dropdown data on component mount
    useEffect(() => {
        const fetchDropdowns = async () => {
            try {
                const res = await getMAMappingCategory_Search();
                setDropdowns({
                    categoryList: res.data.list || []
                });
            } catch (error) {
                showToast(`Error fetching categories: ${error.message}`);
            }
        };
        fetchDropdowns();
    }, [showToast]);

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle single select changes
    const singleSelectChange = (name) => (event, value) => {
        setTempOptions((prev) => ({
            ...prev,
            [name]: value
        }));
        setState((prev) => ({
            ...prev,
            mAMappingCategoryID: value?.id || '',
            mAMappingCategoryName: value?.name || ''
        }));
    };

    // Form validation
    const validateForm = () => {
        const { mAMappingCategoryName, mAMappingCategoryID } = state;
        const errors = {};
        if (!mAMappingCategoryID) {
            errors.mAMappingCategoryID = 'This field is required';
        }
        if (!mAMappingCategoryName.trim()) {
            errors.mAMappingCategoryName = 'This field is required';
        }
        setState((prev) => ({ ...prev, errors }));
        return Object.keys(errors).length === 0;
    };

    // Submit handler
    const submitHandler = async () => {
        if (!validateForm()) return;

        setBtnLoader(true);

        const categoryData = {
            mAMappingCategoryID: state.mAMappingCategoryID,
            mAMappingCategoryName: state.mAMappingCategoryName,
            accountCode: selectedRowData.accountCode || ''
        };

        try {
            const res = await postMANominalCategory_AddUpdate(categoryData);
            if (res.success) {
                showToast('Category updated successfully');
                onClose(true);
            } else {
                showToast(res.message || 'Update failed');
            }
        } catch (error) {
            showToast(`Error: ${error.message}`);
        } finally {
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Category Value"
                    name="mAMappingCategoryID"
                    placeholder="Value"
                    value={state.mAMappingCategoryID}
                    onChange={handleInputChange}
                    errors={state.errors.mAMappingCategoryID}
                    disabled
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Category Name"
                    name="mAMappingCategoryName"
                    placeholder="Name"
                    value={state.mAMappingCategoryName}
                    onChange={handleInputChange}
                    errors={state.errors.mAMappingCategoryName}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel shrink>Category List</InputLabel>
                <SingelSelect
                    options={dropdowns.categoryList || []}
                    value={tempOptions.categoryIDValue}
                    onChange={singleSelectChange('categoryIDValue')}
                    disabled={state.isReadOnly}
                />
                <FormHelperText error>{state.errors.mAMappingCategoryID}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler} fullWidth>
                    {selectedRowData.maMappingCategoryID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default NominalCategoryAddEditModal;
