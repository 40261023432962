import { get, post } from '../http-calls';

export const postMappingCategory = async (data) => {
    let res = await post(`MappingCategory/MappingCategory_AddUpdate`, data, { useAuthToken: true });
    return res;
};

export const getMappingCategoryById = async (id) => {
    let res = await get(`MappingCategory/MappingCategory_GetDetails/${id}`, { useAuthToken: true });
    return res;
};

export const getMANominalAccountHistoryByAccount = async (AccountCode) => {
    let res = await get(`NominalCodes/MANominalAccountHistoryByAccount?AccountCode=${AccountCode || ''}`, { useAuthToken: true });
    return res;
};
export const getMA_GetNominalTransactionDropdowns = async () => {
    let res = await get(`NominalTransaction/MA_GetNominalTransactionDropdowns`, { useAuthToken: true });
    return res;
};
