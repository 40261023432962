import React, { useState } from 'react';
import { Modal, Box, Button, IconButton, Grid, Typography, ImageList, ImageListItem, Badge } from '@material-ui/core';
import { DialogsActions } from '../../../../Core/FormInput';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import DeleteIcon from '@material-ui/icons/Delete';
import BackupIcon from '@material-ui/icons/Backup';

const style = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto'
    },
    imagePreview: {
        position: 'relative',
        '&:hover .deleteButton': {
            opacity: 1
        }
    },
    deleteButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }
    }
};

const ImageUploadModal = ({ handleImageUpload, handleRemoveImage, images, title, disabled }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button onClick={handleOpen} label="Upload Images" disabled={disabled}>
                <Badge badgeContent={images?.length} color="secondary" showZero>
                    <BackupIcon color="primary" />
                </Badge>
            </Button>

            {open ? (
                <DialogComp title={title} maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="image-upload-modal">
                    <Grid container spacing={2} height="100%" alignContent="space-between">
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" component="label">
                                Upload
                                <input type="file" hidden multiple accept="image/*" onChange={handleImageUpload} />
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <ImageList cols={3} gap={8} style={{ height: '380px', overflow: 'auto' }}>
                                {images?.map((image, index) => (
                                    <ImageListItem key={index} sx={style.imagePreview} style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
                                        <img
                                            src={image.preview ? image.preview : image}
                                            alt={`Preview ${index}`}
                                            loading="lazy"
                                            style={{ height: '200px', objectFit: 'cover' }}
                                        />
                                        {/* <IconButton size="small" style={style.deleteButton} onClick={() => handleRemoveImage(index)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </>
    );
};

export default ImageUploadModal;
