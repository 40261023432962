import { Button, CircularProgress, FormHelperText, Grid, InputLabel, Tooltip } from '@material-ui/core';
import { TextBox } from '.';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ReactDOM from 'react-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';

export function MandatoryField(props) {
    const { inputLabel, isDisabled, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value || 'N/A'}</div>
        </div>
    ) : (
        <>
            {/* <GlobalCss /> */}
            <InputLabel required shrink error>
                {inputLabel}
            </InputLabel>

            <TextBox {...rest} />
            <FormHelperText error>{rest.errors}</FormHelperText>
        </>
    );
}

export function SimpleField(props) {
    const { inputLabel, isDisabled, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value || 'N/A'}</div>
        </div>
    ) : (
        <>
            {/* <GlobalCss /> */}
            <InputLabel shrink>{inputLabel}</InputLabel>
            <TextBox {...rest} />
        </>
    );
}

export const EditButton = (props) => {
    const { toolTipTitle, iconFontSize, onClick, ...rest } = props;
    const [show, setShow] = React.useState(false);
    return (
        <span className="modal-btn-update">
            <Tooltip
                title={toolTipTitle || 'Edit'}
                arrow
                open={show}
                disableHoverListener
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <EditIcon
                    style={{ fontSize: iconFontSize || 22, marginTop: 5 }}
                    onClick={() => {
                        setShow(false);
                        if (onClick) return onClick();
                    }}
                    color="secondary"
                    {...rest}
                />
            </Tooltip>
        </span>
    );
};

export const DefaultButton = (props) => {
    const { forceEnabled, toolTipTitle, ...rest } = props;
    // let { isReadOnlyUser } = useLoginInfo();
    // forceEnabled && (isReadOnlyUser = false);
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Button variant="contained" {...rest} fullWidth>
                {!rest.isBtnLoader ? (
                    <>{rest.children}</>
                ) : (
                    <Grid style={{ position: 'relative', width: '100%' }}>
                        <Grid>{rest.children}</Grid>

                        <Grid style={{ position: 'absolute', right: 0, top: '3px' }}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                )}
            </Button>
        </Tooltip>
    );
};

export const YesButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                    {rest.btnName || 'Yes'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const NoButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btninActive' : 'btnActiveNo'} value={true} {...rest}>
                    {rest.btnName || 'No'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const AddBoxButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn">
            <Tooltip title={toolTipTitle || 'Add'} arrow>
                <AddBoxIcon style={{ fontSize: iconFontSize || 22, marginTop: 10, cursor: 'pointer', ...rest.style }} color="secondary" {...rest} />
            </Tooltip>
        </span>
    );
};
