import { Checkbox, FormControlLabel, Grid, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { getMappingPositions_GetDetails, postMappingPositions_AddUpdate } from '../../../../Core/Service/MappingService';
import { SecondaryButton } from '../../../../Core/FormInput';
import { MandatoryField } from '../../../../Core/FormInput/inputfields';

const getUniqueValues = (arr1, arr2) => {
    const numericArr1 = arr1.map(Number); // Convert all elements of arr1 to numbers
    const numericArr2 = arr2.map(Number); // Convert all elements of arr2 to numbers

    // Get unique values that are in arr1 but not in arr2, and vice versa
    const uniqueArr1 = numericArr1.filter((value) => !numericArr2.includes(value));
    const uniqueArr2 = numericArr2.filter((value) => !numericArr1.includes(value));

    return [...uniqueArr1, ...uniqueArr2]; // Combine the unique values from both arrays
};

const MappingAddEdit = (props) => {
    const [state, setState] = useState({
        errors: {},
        MAMappingPositionsValue: [], // Tracks new selections
        MAMappingPositionsTitle: '',
        selectedPositions: props.selectedPosition
    });
    const [loading, setLoading] = useState(false);
    const { showToast } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            if (props.maMappingPositionsID) {
                setLoading(true);
                try {
                    let res = await getMappingPositions_GetDetails(props.maMappingPositionsID);
                    setState((st) => ({
                        ...st,
                        MAMappingPositionsTitle: res.data.list[0].maMappingPositionsTitle,
                        MAMappingPositionsValue: res.data.list[0].maMappingPositionsValue.split(',').map((q) => Number(q)),
                        selectedPositions: getUniqueValues(
                            props.selectedPosition,
                            res.data.list[0].maMappingPositionsValue.split(',').map((q) => Number(q))
                        )
                    }));
                } catch (error) {
                    showToast(`Error fetching data: ${error.message}`);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [props.maMappingPositionsID, props.selectedPosition]);

    const handleCheckboxChange = (number) => {
        setState((prevState) => {
            const currentValue = [...prevState.MAMappingPositionsValue];
            if (currentValue.includes(number)) {
                // Remove the number if it's already selected
                return { ...prevState, MAMappingPositionsValue: currentValue.filter((n) => n !== number) };
            } else {
                // Add the number to the selected values
                return { ...prevState, MAMappingPositionsValue: [...currentValue, number] };
            }
        });
    };

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let data = {
                MAMappingPositionsID: props.maMappingPositionsID || null,
                MAMappingPositionsValue: state.MAMappingPositionsValue.toString(),
                MAMappingPositionsTitle: state.MAMappingPositionsTitle
            };

            try {
                let res = await postMappingPositions_AddUpdate(data);
                if (res.success) {
                    showToast(res.data.message);
                    props.onClose(true);
                } else if (res.errors) {
                    for (const [key, value] of Object.entries(res?.errors)) {
                        showToast(`${key}: ${value}`);
                    }
                } else {
                    showToast(res.message);
                }
            } catch (error) {
                showToast(`Error: ${error.message}`);
            }
        }
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        // Validate title field
        if (!state.MAMappingPositionsTitle) {
            formIsValid = false;
            errors['MAMappingPositionsTitle'] = 'This field is required';
        }

        // Validate selected positions
        if (state.MAMappingPositionsValue.length === 0 && state.selectedPositions.every((num) => state.MAMappingPositionsValue.includes(Number(num)))) {
            formIsValid = false;
            errors['MAMappingPositionsValue'] = 'Please select at least one position';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MandatoryField
                    inputLabel="Title"
                    placeholder="Title"
                    value={state.MAMappingPositionsTitle}
                    onChange={inputChange}
                    name="MAMappingPositionsTitle"
                    errors={state.errors.MAMappingPositionsTitle}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                        <Grid item xs={4} key={number}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            state.selectedPositions.includes(number) || // Checked if in props.selectedPositions
                                            state.MAMappingPositionsValue.includes(number) // Or if selected by the user
                                        }
                                        onChange={() => handleCheckboxChange(number)}
                                        disabled={state.selectedPositions.includes(number)} // Disable if in props.selectedPosition
                                    />
                                }
                                label={`Position ${number}`}
                            />
                        </Grid>
                    ))}
                </Grid>
                {state.errors.MAMappingPositionsValue && <div style={{ color: 'red' }}>{state.errors.MAMappingPositionsValue}</div>}
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton fullWidth onClick={handleSubmitClick}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default MappingAddEdit;
