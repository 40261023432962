import { Edit, Link, Add, Info } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryButton, SquareButton } from '../../../Core/FormInput/AppButton';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import SearchWipsByBranch from '../Service24/SearchWipByBranch';
import AppContext from '../../../App/AppContext';
import { postLinkWips } from '../../../Core/Service/service24-service';
import DialogComp from '../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import EditIcon from '@material-ui/icons/Edit';
import { getLoanVehicleByID } from '../../../Core/Service/loan-vehicle-service';

let styleObj = {
    backgroundColor: '#183B68',
    borderRadius: '20%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    padding: '0px 0px 0px 0px'
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Loan Vehicles', active: true }
];

const LoanVehiclesGrid = (props) => {
    const history = useHistory();

   
    const handelAddUpdate = () => {
        history.push({
            pathname: `/addupdate/loanVehicle`
        });
    };
    

    const baseColumns = [
        { field: 'action', title: `Action`, width: '5%', filterable: false, sortable: false },
        { field: 'branchName', title: `Branch`, filterable: true, sortable: true, width: '5%' },
        { field: 'vehicleRegNumber', title: `Reg No`, filterable: true, sortable: true, width: '6%' },
        { field: 'vehicleMakeModel', title: `Make`, filterable: true, sortable: true, width: '8%' },
        { field: 'vehicleFuelType', title: `Fuel Type`, filterable: true, sortable: true, width: '8%' },
        { field: 'vehicleDateOfRegistration', title: `Reg Date`, filterable: true, sortable: true, renderer: formatters.MonthShortFormatter },
        { field: 'vehicleMOTExpiry', title: `MOT Expiry`, filterable: true, sortable: true, renderer: formatters.MonthShortFormatter },
        { field: 'vehicleTaxDue', title: `Tax Due`, filterable: true, sortable: true, renderer: formatters.MonthShortFormatter },
        { field: 'vehicleActive', title: `Active`, filterable: true, sortable: true, renderer: (value) => (value ? 'Yes' : 'No') },
        { field: 'vehicleCategory', title: `Category`, filterable: true, sortable: true },
        { field: 'type', title: `Type`, filterable: true, sortable: true },
        { field: 'vehicleAssetNumber', title: `Asset Number`, filterable: true, sortable: true },
        { field: 'vehicleType', title: `Type`, filterable: true, sortable: true }
    ];

    const addButton = () => {
        
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <abbr title="Add Loan Vehicle">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '20px'
                    }}
                    onClick={() => handelAddUpdate(null)} 
                >
                    <Add fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
                </abbr>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    

    const goToVehicleDetails = async (row) => {
        if (!row || !row.vehicleID) {
            console.error('Vehicle ID is missing');
            return;
        }

        try {
            history.push({
                pathname: '/addupdate/loanVehicle',
                state: { vehicleData: row.vehicleID }
            });
        } catch (error) {
            console.error('Error fetching vehicle data:', error);
        }
    };

    const editButton = () => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <abbr title="Edit Loan Vehicle details"> <SquareButton style={styleObj} onClick={() => goToVehicleDetails(record)}>
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
                </abbr>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        return tempCols;
    }, []);

    const baseUrl = `loanVehicle`;

    return (
        <div>
            <div className="report-screen-container">
                <BreadCrumbs crumbs={crumbs} />
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data?.list,
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            AddBtn={addButton}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default LoanVehiclesGrid;
