import { get, post } from './http-calls';

export const getLoanVehicleUsage = async (id) => {
    let res = await get(`LoanVehicleUsage/${id}`, { useAuthToken: true });
    return res;
};

export const LoanVehicleSearch = async (id) => {
    let res = await get(`LoanVehicle/LoanVehicle_Search?searchTerm=${id}`, { useAuthToken: true });
    return res;
};

export const postLoanVehicleUsage = async (data) => {
    let res = await post(`LoanVehicleUsage`, data, { useAuthToken: true });
    return res;
};
