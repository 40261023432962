import { InputLabel, Grid, Typography, FormHelperText } from '@material-ui/core';
import './loanStyle.scss';
import { TextBox } from '../../../Core/FormInput';
import { Email } from '@material-ui/icons';
import { useContext, useState } from 'react';
import { LoanVehicleContext } from './loanVehicle-provider';

export const CompanyDetails = (props) => {
    const { handleCompanyContactDetails, companyDetails, errors, state } = useContext(LoanVehicleContext);

    return (
        <Grid container spacing={1} className="mainSection">
            <Grid item xs={12}>
                <div className="Loan_Veh_heading">Company Details</div>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                {' '}
                                <InputLabel shrink component="legend" required error>
                                    Company
                                </InputLabel>
                                <TextBox
                                    name="companyContactCompany"
                                    value={companyDetails.companyContactCompany}
                                    onChange={handleCompanyContactDetails}
                                    required
                                    type="text"
                                    maxLength={100}
                                    multiline
                                    rows={4}
                                    disabled={state.isVehicleReceived}
                                />
                                {errors.companyContactCompany && <FormHelperText error>{errors.companyContactCompany}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel shrink component="legend" required error>
                                    Address
                                </InputLabel>
                                <TextBox
                                    name="companyContactAddress"
                                    value={companyDetails.companyContactAddress}
                                    onChange={handleCompanyContactDetails}
                                    required
                                    type="text"
                                    maxLength={200}
                                    multiline
                                    rows={4}
                                    disabled={state.isVehicleReceived}
                                />
                                {errors.companyContactAddress && <FormHelperText error>{errors.companyContactAddress}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6} style={{ padding: '0px 10px 0px 0px' }}>
                                <InputLabel shrink component="legend" required error>
                                    Contact name
                                </InputLabel>
                                <TextBox
                                    name="companyContactName"
                                    value={companyDetails.companyContactName}
                                    onChange={handleCompanyContactDetails}
                                    required
                                    type="text"
                                    maxLength={50}
                                    disabled={state.isVehicleReceived}
                                />
                                {errors.companyContactName && <FormHelperText error>{errors.companyContactName}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink component="legend">
                                    Tel no
                                </InputLabel>
                                <TextBox
                                    name="companyContactNumber"
                                    value={companyDetails.companyContactNumber}
                                    onChange={handleCompanyContactDetails}
                                    required
                                    type="tel"
                                    pattern="[0-9]{10,11}"
                                    maxLength={11}
                                    disabled={state.isVehicleReceived}
                                />
                                {errors.companyContactNumber && <FormHelperText error>{errors.companyContactNumber}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12}>
                                {/* <InputLabel shrink component="legend" required error>
                                    Email
                                </InputLabel> */}
                                <TextBox
                                    name="companyContactEmail"
                                    value={companyDetails.companyContactEmail}
                                    onChange={handleCompanyContactDetails}
                                    required
                                    label="Email"
                                    type="email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    disabled={state.isVehicleReceived}
                                />
                                {errors.companyContactEmail && <FormHelperText error>{errors.companyContactEmail}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={4} xl={4}>
                    <InputLabel shrink component="legend" required error>
                        Company
                    </InputLabel>
                    <TextBox
                        name="companyContactCompany"
                        value={companyDetails.companyContactCompany}
                        onChange={handleCompanyContactDetails}
                        required
                        type="text"
                        maxLength={100}
                        multiline
                        rows={3}
                    />
                    {errors.companyContactCompany && <FormHelperText error>{errors.companyContactCompany}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <InputLabel shrink component="legend" required error>
                        Address
                    </InputLabel>
                    <TextBox
                        name="companyContactAddress"
                        value={companyDetails.companyContactAddress}
                        onChange={handleCompanyContactDetails}
                        required
                        type="text"
                        maxLength={200}
                        multiline
                        rows={3}
                    />
                    {errors.companyContactAddress && <FormHelperText error>{errors.companyContactAddress}</FormHelperText>}
                </Grid> */}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                {' '}
                <InputLabel shrink component="legend">
                    Reason for use
                </InputLabel>
                <TextBox
                    name="companyContactReasonForUse"
                    value={companyDetails.companyContactReasonForUse}
                    onChange={handleCompanyContactDetails}
                    required
                    type="text"
                    maxLength={200}
                    disabled={state.isVehicleReceived}
                    // multiline
                    // rows={2}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                <InputLabel shrink component="legend">
                    Postcode
                </InputLabel>
                <TextBox
                    name="companyContactPostCode"
                    value={companyDetails.companyContactPostCode}
                    onChange={handleCompanyContactDetails}
                    required
                    type="text"
                    maxLength={8}
                    pattern="^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                    disabled={state.isVehicleReceived}
                />
                {errors.companyContactPostCode && <FormHelperText error>{errors.companyContactPostCode}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                <InputLabel shrink component="legend" required error>
                    Driver name
                </InputLabel>
                <TextBox
                    name="companyDriverName"
                    value={companyDetails.companyDriverName}
                    onChange={handleCompanyContactDetails}
                    required
                    type="text"
                    maxLength={50}
                    disabled={state.isVehicleReceived}
                />
                {errors.companyDriverName && <FormHelperText error>{errors.companyDriverName}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                <InputLabel shrink component="legend" required error>
                    Vehicle Reg
                </InputLabel>
                <TextBox
                    name="companyRegistrationNumber"
                    value={companyDetails.companyRegistrationNumber}
                    onChange={handleCompanyContactDetails}
                    required
                    type="text"
                    maxLength={10}
                    pattern="^[A-Z0-9]{2,10}$"
                    style={{ textTransform: 'uppercase' }}
                    disabled={state.isVehicleReceived}
                />
                {errors.companyRegistrationNumber && <FormHelperText error>{errors.companyRegistrationNumber}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                <InputLabel shrink component="legend" required error>
                    WIP no
                </InputLabel>
                <TextBox
                    name="companyWIPNumber"
                    value={companyDetails.companyWIPNumber}
                    onChange={handleCompanyContactDetails}
                    type="number"
                    min={0}
                    maxLength={10}
                    disabled={state.isVehicleReceived}
                />
                {errors.companyWIPNumber && <FormHelperText error>{errors.companyWIPNumber}</FormHelperText>}
            </Grid>
        </Grid>
    );
};
