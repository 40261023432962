import { memoize } from 'lodash';
import { get, post, put } from './http-calls';
import moment from 'moment';

export const getAllUsers = async () => {
    let res = await get('/users', { useAuthToken: true });
    return res;
};

export const getUser = async (data) => {
    let res = await get(`/users/${data}`, { useAuthToken: true });
    return res;
};

export const getHolidaysScreenDropdown = async (data) => {
    let res = await get(`Holidays/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getHolidaysList = async (Year) => {
    let res = await get(`Holidays?Year=${Year}`, { useAuthToken: true });
    return res;
};

export const AddHolidays = async (data) => {
    let res = await post('Holidays', data, { useAuthToken: true });
    return res;
};

export const getScreenDropdown = async () => {
    let res = await get(`/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getCustomerScreenDropdown = memoize(async () => {
    let res = await get(`/CustomerScreenDropdown`, { useAuthToken: true });
    return res;
});

export const Adduser = async (data) => {
    let res = await post('/register', data, { useAuthToken: true });
    return res;
};

export const UpdateUser = async (data) => {
    let res = await put(`/users/${data.id}`, data, { useAuthToken: true });
    return res;
};

export const ResetPassByAdmin = async (userId, newpassword, confirm) => {
    let res = await post(
        `/overwritePassword/${userId}`,
        {
            newPassword: newpassword,
            confirmPassword: confirm
        },
        { useAuthToken: true }
    );
    return res;
};

export const getAdminPartById = async (id) => {
    let res = await get(`AdminParts/Parts_GetDetails/${id}`, { useAuthToken: true });
    return res;
};
export const AddParts = async (data) => {
    let res = await post('AdminParts/Part_AddUpdate', data, { useAuthToken: true });
    return res;
};

export const postImportParts = async (FileData) => {
    let res = await post(`AdminParts/ImportPartsData`, FileData, { useAuthToken: true });
    return res;
};

export const getUserExcelFile = async (URL, FileName) => {
    let res = await get(URL, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    if (true) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // const url = URL;
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `${FileName} ${moment().format('DD-MMM-YYYY HH:mm')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};
