import React, { useState, useEffect, useMemo, useContext } from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import formatters from '../../../../../Core/Grid/inputFormatter';
import MananageAccountGrid from '../../../../managementAccount/manageAccountGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Nominal Departments', active: true }
];
const basecolumns = [
    { field: 'costCentre', title: 'Cost Centre', width: 200 },
    { field: 'shortName', title: 'Short Name', width: 300 },
    { field: 'departmentCodeDescription', title: 'Department Code Description', width: 600 },
    { field: 'costCentreGroup', title: 'Cost Centre Group', width: 200 },
    { field: 'divisionDescription', title: 'Division Description', width: 250 },
    { field: 'lastUpdated', title: 'Last Updated', renderer: formatters.DateTimeFormatter, width: 300 }
];
const NominalDept = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamDivisionCode = params.get('divisionCode') || '';
    const [state, setState] = useState({ gridUrl: `NominalDepartmentCodes/NominalDepartmentCodes_List` });
    useEffect(() => {
        if (ParamDivisionCode) {
            setState((st) => ({ ...st, gridUrl: `NominalDepartmentCodes/NominalDepartmentCodes_List?divisionCode=${ParamDivisionCode}` }));
        }
    }, [ParamDivisionCode]);

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <MananageAccountGrid
                columns={basecolumns}
                getUrl={state.gridUrl}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
            />
        </div>
    );
};

export default NominalDept;
